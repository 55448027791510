import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { className, renderText, isNonEmptyArray } from 'utils';
import TopperContentLeft from './TopperContentLeft';
import TopperContentRight from './TopperContentRight';

import styles from './landing.module.scss';
import WideRightArrow from '../../../svgs/Arrow/WideRight';

const Landing = ({ headline, subhead, topperLeftContent, topperRightContent }) => {
  const numLinksRef = useRef();

  const numAnchorTags = numLinksRef.current
    ? numLinksRef.current.getElementsByTagName('a').length
    : 0;

  const showArrow = numAnchorTags === 1;

  const getArrowProps = () => {
    const firstHref = numLinksRef.current.querySelector('a');
    const href = firstHref.attributes['href'].value;
    const text = firstHref.text;

    return { ariaLabel: text, to: href };
  };

  return (
    <section {...className(styles.landing, 'page-left-inset', 'page-left-inset-topper')}>
      <div className={styles.topperLeft}>
        <header className={styles.header}>
          <h1 {...className(styles.headline, 'js-animate-text')}>{headline}</h1>
          {subhead && (
            <div {...className(styles.intro, 'js-animate-text')}>
              <div className={styles.introText} ref={numLinksRef}>
                {renderText(subhead)}
                {showArrow && (
                  <Link {...getArrowProps()} className={styles.arrowLink}>
                    <WideRightArrow className={styles.arrow} />
                  </Link>
                )}
              </div>
            </div>
          )}
        </header>

        <div className={styles.blocks}>
          <div className={styles.bigBlocks}>
            {isNonEmptyArray(topperLeftContent) &&
              topperLeftContent.map(({ intro, image, link }, i) => (
                <TopperContentLeft intro={intro} image={image} link={link} key={i} />
              ))}
          </div>
        </div>
      </div>

      <div className={styles.topperRight}>
        <div className={styles.blocks}>
          <div className={styles.smallBlocks}>
            {isNonEmptyArray(topperRightContent) &&
              topperRightContent.map(({ headline, intro, image, link, format }, i) => (
                <TopperContentRight
                  headline={headline}
                  intro={intro}
                  image={image}
                  link={link}
                  format={format ? format[0] : 'Row'}
                  key={i}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Landing.propTypes = {
  headline: PropTypes.string.isRequired,
  subhead: PropTypes.object,
  topperLeftContent: PropTypes.array,
  topperRightContent: PropTypes.array,
};

export default Landing;
