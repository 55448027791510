import React from 'react';
import PropTypes from 'prop-types';
import { className, safeGet } from 'utils';
import { authorPortraitParams } from 'utils/imageQueryParams';
import { imagePropTypes } from 'utils/proptypes';
import BleedRight from '../../MediaLayout/BleedRight';
import Link from '../../Link';
import styles from './textWidthBlockquote.module.scss';

const TextWidthBlockquote = ({
  content,
  withQuotes = true,
  attribution,
  attributionSecondLine,
  attributionLink,
  attributionThumbnail,
}) => {
  const { url: authorImageUrl, description: authorImageDescription } = safeGet(
    attributionThumbnail,
    'description',
    ['file', 'url'],
  );

  return (
    <div className={authorImageUrl && styles.quoteWithImageWrapper}>
      <figure {...className(styles.inlineContent, withQuotes && styles.withQuotes)}>
        <blockquote className={styles.quoteBody}>{content}</blockquote>
        {attribution && (
          <figcaption className={styles.attribution}>
            {authorImageUrl && (
              <div className={styles.portraitWrapper}>
                <img
                  src={`${authorImageUrl}${authorPortraitParams}`}
                  alt={authorImageDescription || attribution}
                />
              </div>
            )}
            <div>
              <cite className={styles.author}>{attribution}</cite>
              {attributionLink ? (
                <Link to={attributionLink}>{attributionSecondLine}</Link>
              ) : (
                attributionSecondLine
              )}
            </div>
          </figcaption>
        )}
      </figure>
      {authorImageUrl && (
        <BleedRight image={{ src: authorImageUrl, title: attribution }} className={styles.image} />
      )}
    </div>
  );
};

TextWidthBlockquote.propTypes = {
  attribution: PropTypes.string,
  attributionSecondLine: PropTypes.string,
  attributionLink: PropTypes.string,
  attributionThumbnail: imagePropTypes,
  content: PropTypes.string.isRequired,
  image: imagePropTypes,
  withQuotes: PropTypes.bool,
};

export default TextWidthBlockquote;
