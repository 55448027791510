import React from 'react';
import PropTypes from 'prop-types';

const Caret = ({ className: customClassName }) => (
  <svg
    className={customClassName}
    data-name="Icon/Caret"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 11">
    <path
      data-name="Icon/Caret"
      d="M20,1.39,18.594,0,9.987,8.261,9.069,7.38l.005.005L1.427.045,0,1.414,9.987,11,20,1.39"
      fill="#9ce2fd"
    />
  </svg>
);

Caret.propTypes = {
  className: PropTypes.string,
};

export default Caret;
