import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { menuPropTypes } from 'utils/proptypes';
import { className, renderText } from 'utils';
import Navigation from '../Page/Navigation';
import styles from './bodyContent.module.scss';
import basicPageStyles from '../Page/BasicPageTopper/basicPageTopper.module.scss';

const BodyContent = ({ children, content, className: customClassName, menu }) => (
  <Fragment>
    <div className="page-left-inset page-left-inset-content body-content">
      <section {...className(customClassName, styles.corePageContent, styles.basicPageBody)}>
        {children}
        {content && renderText(content)}
      </section>
    </div>
    {menu && <Navigation className={basicPageStyles.pagesNavigationFooter} menu={menu} />}
  </Fragment>
);

BodyContent.propTypes = {
  children: PropTypes.node,
  content: PropTypes.object,
  className: PropTypes.string,
  overline: PropTypes.string,
  menu: menuPropTypes,
  location: PropTypes.object,
};

export default BodyContent;
