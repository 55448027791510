import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
// import styles from './Pathways.module.scss';

const Pathways = ({ className: customClassName }) => (
  <svg {...className(customClassName)} viewBox="0 0 930 594">
    <g opacity="0.9">
      <path
        d="M0 365.826L243.092 365.931L930 0V49.521L257.913 377.975L0 391.782V365.826Z"
        fill="#EEE8E2"
      />
      <path
        d="M0 412.955L271.287 388.803L930 106.428V155.844L285.983 401.057L0 441.975V412.955Z"
        fill="#EEE8E2"
      />
      <path
        d="M0 467.533L299.818 412.451L930 211.178V261.958L314.577 424.496L0 493.846V467.533Z"
        fill="#EEE8E2"
      />
      <path
        d="M0 518.669L327.677 435.491L930 314.773V366.518L342.121 447.451L0 543.912V518.669Z"
        fill="#EEE8E2"
      />
      <path
        d="M0 569.428L352.849 456.348L930 417.697V469.925L370.211 470.659L0 594V569.428Z"
        fill="#EEE8E2"
      />
    </g>
  </svg>
);

Pathways.propTypes = {
  className: PropTypes.string,
};

export default Pathways;
