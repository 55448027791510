import React from 'react';
import { className } from 'utils';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './buttonLink.module.scss';

const ButtonLink = ({ buttonLink, buttonLabel, className: customClassName }) => (
  <div {...className(styles.buttonWrapper, customClassName, 'component button-link')}>
    <Link className={styles.button} to={buttonLink} newTab>
      {buttonLabel}
    </Link>
  </div>
);

ButtonLink.propTypes = {
  buttonLink: PropTypes.string,
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonLink;
