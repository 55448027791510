export const isBrowser = () => typeof window !== 'undefined';
export const getUser = () =>
  isBrowser() && window.localStorage.getItem('clientUser')
    ? JSON.parse(window.localStorage.getItem('clientUser'))
    : {};
const setUser = user => window.localStorage.setItem('clientUser', JSON.stringify(user));

export const handleLogin = ({ password, passwordMatch }) => {
  if (password === passwordMatch) {
    return setUser({
      loggedIn: password,
    });
  }
  return false;
};

export const isLoggedIn = passwordMatch => getUser().loggedIn === passwordMatch;

export const logout = callback => {
  setUser({});
  callback();
};
