import React from 'react';
import { className } from 'utils';
import PropTypes from 'prop-types';

import styles from './playIcon.module.scss';

const PlayIcon = ({ className: customClassName }) => (
  <div {...className(styles.playIcon, customClassName)}>
    <svg className={styles.icon} viewBox="0 0 494.942 494.942" xmlns="http://www.w3.org/2000/svg">
      <path d="m35.353 0 424.236 247.471-424.236 247.471z" />
    </svg>
  </div>
);

PlayIcon.propTypes = {
  className: PropTypes.string,
};

export default PlayIcon;
