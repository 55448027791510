import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { Link } from 'components';
import styles from './twoUp.module.scss';
import Wide from '../Wide';
import { imagePropTypes } from 'utils/proptypes';
import { className, safeGet, renderText, getQueryParams } from 'utils';

const TwoUp = props => {
  const { media, caption, link, className: customClassName } = props;

  if (isEmpty(media)) {
    return null;
  }

  const getImageQueryParams = i => {
    const [w, h] = i === 0 ? [800, 1200] : [1200, 800];
    return getQueryParams({ w, h, fit: 'fill' });
  };

  const twoMedia = media.slice(0, 2).map((asset, i) => {
    const { url, ...rest } = safeGet(asset, 'title', 'description', ['file', 'url']);
    return { url: `${url}${getImageQueryParams(i)}`, ...rest };
  });

  const isLast = i => i === twoMedia.length - 1;

  if (twoMedia.length === 1) {
    return <Wide {...props} />;
  }

  return (
    <div {...className(customClassName, 'component', styles.twoUp)}>
      <figure className={styles.figure}>
        {twoMedia.map(({ title, description, url }, i) => (
          <div className={styles.imageWrap} key={i}>
            {link ? (
              <Link to={link}>
                <img src={url} alt={description || title} />
              </Link>
            ) : (
              <img src={url} alt={description || title} />
            )}
            {isLast(i) && caption && (
              <figcaption className={styles.caption}>{renderText({ json: caption })}</figcaption>
            )}
          </div>
        ))}
      </figure>
    </div>
  );
};

TwoUp.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.object,
  media: PropTypes.arrayOf(imagePropTypes),
  link: PropTypes.string,
};

export default TwoUp;
