import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { imagePropTypes } from 'utils/proptypes';
import { className, renderText, safeGet, getQueryParams } from 'utils';
import styles from './wide.module.scss';

const Wide = ({ caption, media, link, className: customClassName }) => {
  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    media,
    [0, 'title'],
    [0, 'description'],
    [0, 'file', 'url'],
  );

  if (!imageUrl) {
    return null;
  }

  const imageContent = (
    <img src={`${imageUrl}${getQueryParams({ w: 1200 })}`} alt={imageDescription || imageTitle} />
  );

  const imageComponent = link ? <Link to={link}>{imageContent}</Link> : imageContent;

  return (
    <div {...className(customClassName, 'component', styles.wide)}>
      <figure className={styles.figure}>
        <div className={styles.imageWrap}>
          {imageComponent}
          {caption && (
            <figcaption className={styles.caption}>{renderText({ json: caption })}</figcaption>
          )}
        </div>
      </figure>
    </div>
  );
};

Wide.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.object,
  media: PropTypes.arrayOf(imagePropTypes),
  link: PropTypes.string,
};

export default Wide;
