import React from 'react';
import PropTypes from 'prop-types';
import { className, getQueryParams, isNonEmptyArray, safeGet, parseShortText } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import { IMAGE_DIMENSIONS } from 'utils/constants';
import styles from './advisorProfileTopper.module.scss';

const AdvisorProfileTopper = ({ intro, title, name, data, image }) => {
  const { description: imageDescription, url: imageUrl } = safeGet(image, 'title', 'description', [
    'file',
    'url',
  ]);

  const slicedData = data.slice(0, 2);

  return (
    <header className="page-left-inset page-left-inset-topper">
      <div {...className(styles.topperWrapper, imageUrl && styles.withHangingImage)}>
        {imageUrl && (
          <div className={styles.advisorProfileImage}>
            <img
              src={`${imageUrl}${getQueryParams(IMAGE_DIMENSIONS)}`}
              alt={imageDescription || name}
            />
          </div>
        )}
        <section className={styles.headerWrapper}>
          {title && <h1 className={styles.title}>{parseShortText(title)}</h1>}
          {intro && <p className={styles.intro}>{parseShortText(intro.intro)}</p>}

          {isNonEmptyArray(slicedData) && (
            <dl className={styles.advisorInfo}>
              {slicedData.map(
                ({ label, text }, i) =>
                  (label || text) && (
                    <div className={styles.advisorInfoBlock} key={i}>
                      <dt className={styles.advisorInfoHead}>{label}</dt>
                      <dd className={styles.advisorInfoValue}>{text}</dd>
                    </div>
                  ),
              )}
            </dl>
          )}
        </section>
      </div>
    </header>
  );
};

AdvisorProfileTopper.propTypes = {
  name: PropTypes.string,
  intro: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  image: PropTypes.arrayOf(imagePropTypes),
};

export default AdvisorProfileTopper;
