import React from 'react';
import PropTypes from 'prop-types';
import { className, safeGet, renderText } from 'utils';
import { imagePropTypes } from '../../utils/proptypes';
import { Link } from 'components';

import styles from './hero.module.scss';

const HeroBanner = ({
  headline,
  content,
  buttonSlug,
  buttonAsset,
  buttonText,
  heroImage,
  leftPanel,
  panelColor,
}) => {
  const { url: imageURL } = safeGet(heroImage, ['file', 'url'], ['description'], ['title']);

  const { url: assetURL } = safeGet(buttonAsset, ['file', 'url'], ['description'], ['title']);

  const buttonURL = buttonAsset ? assetURL : buttonSlug;

  let heroColor = 'forest';

  switch (panelColor) {
    case 'forest':
      heroColor = styles.forest;
      break;
    case 'white':
      heroColor = styles.white;
      break;
    case 'digitalLightGray':
      heroColor = styles.gray;
      break;
    case 'paleSkyBlue':
      heroColor = styles.blue;
      break;
  }

  return (
    <div
      {...className(
        'component',
        styles.heroModule,
        heroColor,
        leftPanel ? styles.panelLeft : styles.panelRight,
      )}
      style={{ backgroundImage: `url(${imageURL})` }}>
      <div {...className(styles.panel)}>
        <div {...className(styles.copy)}>
          {headline && <h1 {...className(styles.headline)}>{headline}</h1>}
          {content && <p {...className(styles.copyText)}>{renderText({ json: content })}</p>}
        </div>
        <div className={styles.buttonWrapperFull}>
          <Link {...className(styles.button)} to={buttonURL} newTab>
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};

HeroBanner.propTypes = {
  headline: PropTypes.string,
  content: PropTypes.string,
  buttonSlug: PropTypes.string,
  buttonAsset: PropTypes.object,
  buttonText: PropTypes.string,
  heroImage: imagePropTypes.isRequired,
  leftPanel: PropTypes.boolean,
  panelColor: PropTypes.string,
};

export default HeroBanner;
