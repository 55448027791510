import React from 'react';
import { className, safeGet, renderText } from 'utils';
import styles from './iconCTAGridItem.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components';

const IconCTAGridItem = props => {
  const {
    url: imageURL,
    description: imageDescription,
    title: imageTitle,
    headline,
    intro,
    ctaUrl,
    introRichText,
  } = safeGet(
    props,
    ['image', 'file', 'url'],
    ['image', 'title'],
    ['image', 'description'],
    'headline',
    'intro',
    'ctaUrl',
    'introRichText',
  );

  if (!headline) {
    return null;
  }

  return (
    <li {...className(styles.item, styles.itemWithImage)}>
      <div className={styles.itemInner}>
        <div className={styles.featuredImgWrapper}>
          {ctaUrl ? (
            <Link to={ctaUrl}>
              <img
                className={styles.featuredImg}
                src={imageURL}
                alt={imageDescription || imageTitle}
              />
            </Link>
          ) : (
            <img src={imageURL} alt={imageDescription || imageTitle} />
          )}
        </div>
        <div className={styles.text}>
          <h1 className={styles.title}>
            {ctaUrl ? (
              <Link to={ctaUrl} className={styles.titleLink}>
                {headline}
              </Link>
            ) : (
              headline
            )}
          </h1>
          {intro && <p className={styles.intro}>{intro}</p>}
          {introRichText && (
            <div className={styles.introRichText}>{renderText({ json: introRichText })}</div>
          )}
        </div>
      </div>
    </li>
  );
};

IconCTAGridItem.propTypes = {
  props: PropTypes.object,
};

export default IconCTAGridItem;
