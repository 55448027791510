import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './socialLink.module.scss';

const SocialLink = ({ text, to, logoSvg, noFocus }) => {
  const LogoSVG = logoSvg;
  const isEmail = to.includes('mailto');

  return (
    <li className={styles.socialLink}>
      {isEmail ? (
        <a href={to}>
          <span className={styles.visuallyHidden}>{text}</span>
          <LogoSVG className={styles.svg} />
        </a>
      ) : (
        <Link to={to} rel="noopener noreferrer" newTab noFocus={noFocus}>
          <span className={styles.visuallyHidden}>{text}</span>
          <LogoSVG className={styles.svg} />
        </Link>
      )}
    </li>
  );
};

SocialLink.propTypes = {
  logoSvg: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  noFocus: PropTypes.bool,
};

export default SocialLink;
