import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { className, getQueryParams, parseShortText } from 'utils';
import { menuPropTypes, imagePropTypes } from 'utils/proptypes';
import { IMAGE_DIMENSIONS } from 'utils/constants';
import Navigation from '../Navigation';
import styles from './basicPageTopper.module.scss';

const BasicPageTopper = ({
  className: customClassName,
  intro,
  title,
  menu,
  image,
  isHorizontal,
}) => {
  const imageUrl = get(image, 'file.url') || get(image, 'media[0].file.url');
  const finalImage = isHorizontal ? imageUrl : `${imageUrl}${getQueryParams(IMAGE_DIMENSIONS)}`;
  const useImage = !menu && !!imageUrl;
  const [topperWrapperClassName, setTopperWrapperClassName] = useState('');
  const isTanBackgroundMenu = menu?.tanBackgroundUse;
  useEffect(() => {
    const classObject = className(
      styles.topperWrapper,
      menu && styles.withPageNav,
      useImage && styles.withImage,
      isTanBackgroundMenu && styles.tanBackgroundMenu,
    );
    setTopperWrapperClassName(classObject.className);
  }, [useImage, menu]);

  return (
    <header {...className(customClassName, 'page-left-inset page-left-inset-topper')}>
      <div className={topperWrapperClassName}>
        <section className={styles.headerWrapper}>
          {title && <h1 className={styles.title}>{parseShortText(title)}</h1>}
          {intro && <p className={styles.intro}>{parseShortText(intro.intro)}</p>}
        </section>
        {menu && <Navigation className={styles.pagesNavigationHeader} menu={menu} />}
        {useImage && (
          <span className={styles.image}>
            <img src={finalImage} alt={image?.description || image?.title} />
          </span>
        )}
      </div>
    </header>
  );
};

BasicPageTopper.propTypes = {
  className: PropTypes.string,
  intro: PropTypes.object,
  title: PropTypes.string,
  menu: menuPropTypes,
  image: imagePropTypes,
  isHorizontal: PropTypes.bool,
};

export default BasicPageTopper;
