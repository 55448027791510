import React from 'react';
import { className, safeGet, getQueryParams } from 'utils';
import styles from './sidebarImageCTA.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components';

const SidebarImageCTA = props => {
  const {
    url: imageURL,
    description: imageDescription,
    title: imageTitle,
    type,
    headline,
    intro,
    buttonLink,
    buttonText,
  } = safeGet(
    props,
    ['photo', 'file', 'url'],
    ['photo', 'description'],
    ['photo', 'title'],
    'type',
    'headline',
    'intro',
    'buttonLink',
    'buttonText',
  );

  const isTypeTwo = type !== 'Option 1';

  if (!headline) {
    return null;
  }

  return (
    <aside {...className(styles.wrapper, isTypeTwo && styles.typeTwoStyle)}>
      <div className={styles.featuredImgWrapper}>
        <div className={styles.clippingBox}>
          <img
            className={styles.featuredImg}
            src={`${imageURL}${getQueryParams(isTypeTwo ? { w: 140, h: 185, fit: 'fill' } : { w: 400, h: 275, fit: 'fill' })}`}
            alt={imageDescription || imageTitle}
          />
        </div>
      </div>
      <div className={styles.text}>
        <h1 className={styles.title}>{headline}</h1>
        <p className={styles.intro}>{intro}</p>
      </div>
      <Link
        {...className(styles.button)}
        to={buttonLink}
        newTab>
        {buttonText}
      </Link>
    </aside>
  );
};

SidebarImageCTA.propTypes = {
  props: PropTypes.object,
};

export default SidebarImageCTA;
