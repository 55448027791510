import React from 'react';
import PropTypes from 'prop-types';

import styles from './slideShare.module.scss';

const SlideShare = ({ slideShareKey }) => (
  <div className={styles.slideShare} data-automation-id={`slideshare-${slideShareKey}`}>
    <iframe
      className="component"
      title={`SlideShare-${slideShareKey}`}
      src={`//www.slideshare.net/slideshow/embed_code/key/${slideShareKey}`}
      width="100%"
      frameBorder="0"
      marginWidth="0"
      marginHeight="0"
      scrolling="no"
      allowFullScreen
    />
  </div>
);

SlideShare.propTypes = {
  slideShareKey: PropTypes.string.isRequired,
};

export default SlideShare;
