import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { BASE_PATHS, className } from 'utils';
import { carouselItemsPropTypes } from 'utils/proptypes';
import { Carousel } from 'components';
import Slide from '../Slide/index.js';
import TopRightButton from '../TopRightButton';
import styles from './richTextCarousel.module.scss';
import { useLocation } from '@reach/router';

const RichTextCarousel = ({
  title,
  description,
  buttonText,
  link,
  carouselItems,
  name,
  isVideoCarousel,
}) => {
  const location = useLocation();
  const isAdvisorProfile = location.pathname.includes(BASE_PATHS.advisors);
  //filter list to exclude items with links that match the current url, for advisor profile carousels
  const carouselItemsList = isAdvisorProfile
    ? carouselItems.filter(item => location.pathname !== item.link)
    : carouselItems;

  return isEmpty(carouselItems) ? null : (
    <Carousel
      {...className('component', styles.richTextCarousel, isVideoCarousel && styles.videoCarousel)}
      title={title}
      description={description}
      name={name}
      isVideoCarousel={isVideoCarousel}
      topRightButton={<TopRightButton text={buttonText} link={link} className={styles.dark} />}>
      {carouselItemsList.map((carouselItem, i) => (
        <Slide
          key={i}
          image={carouselItem.image}
          link={carouselItem.link}
          video={carouselItem.video ? carouselItem.video[0] : null}
          caption={carouselItem.caption}
          isVideoCarousel={isVideoCarousel}
        />
      ))}
    </Carousel>
  );
};

RichTextCarousel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  carouselItems: carouselItemsPropTypes.isRequired,
  name: PropTypes.string.isRequired,
  isVideoCarousel: PropTypes.bool,
};

export default RichTextCarousel;
