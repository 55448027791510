import React from 'react';
import { className } from 'utils';
import styles from './pathways.module.scss';

const Pathways = () => (
  <section {...className(styles.case_cover, 'gl_object_cover')} data-color="0xffffff">
    <div className={styles.group}>
      <div
        {...className(styles.stripe, 'gl_object_stripe')}
        data-color="0x3a5548"
        data-delta="200"
        data-ratio="300"
        data-index="1"></div>
      <div
        {...className(styles.stripe, 'gl_object_stripe')}
        data-color="0x3a5548"
        data-delta="200"
        data-ratio="300"
        data-index="2"></div>
      <div
        {...className(styles.stripe, 'gl_object_stripe')}
        data-color="0x3a5548"
        data-delta="200"
        data-ratio="300"
        data-index="3"></div>
      <div
        {...className(styles.stripe, 'gl_object_stripe')}
        data-color="0x3a5548"
        data-delta="200"
        data-ratio="300"
        data-index="4"></div>
    </div>
  </section>
);

export default Pathways;
