import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import { Link } from 'components';
import styles from './topRightButton.module.scss';

const TopRightButton = ({ text, link, className: customClassName }) =>
  link ? (
    <Link to={link} {...className(customClassName, styles.buttonLink)}>
      {text}
    </Link>
  ) : null;

TopRightButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};

export default TopRightButton;
