import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components';
import { BASE_PATHS, getSlugFromAuthorName, getQueryParams, safeGet } from 'utils';
import { authorPropTypes, topicPropTypes } from 'utils/proptypes';

import styles from './authorTease.module.scss';

const AuthorTease = ({ author, date, topic, readingTime }) => {
  const { name, url, description } = safeGet(
    author,
    'name',
    ['image', 'file', 'url'],
    ['image', 'description'],
  );
  const { name: topicName, slug: topicSlug } = safeGet(topic, 'slug', 'name');

  const topicLink = topicName && (
    <Link to={`/${BASE_PATHS.insights}/${topicSlug}`}>{topicName}</Link>
  );
  const authorSecondLine = (
    <p className={styles.dateCategory}>
      {date} {date && topicName && 'in'} {topicLink}
    </p>
  );

  return (
    <div className={styles.author}>
      {url && (
        <div className={styles.authorPortrait}>
          <img
            className={styles.authorImage}
            alt={description || name}
            src={`${url}${getQueryParams({ w: 60, h: 60, fit: 'fill' })}`}
          />
        </div>
      )}
      <div className={styles.authorInfo}>
        {name &&
          (getSlugFromAuthorName(name) ? (
            <Link
              className={styles.link}
              to={`/${BASE_PATHS.author}/${getSlugFromAuthorName(name)}`}>
              {name}
            </Link>
          ) : (
            <p className={styles.authorName}>{name}</p>
          ))}
        {(date || topicName) && authorSecondLine}
        {readingTime && (
          <p className={styles.readingTime}>
            Estimated Reading Time: {Math.ceil(readingTime.minutes)} Minutes ({readingTime.words}{' '}
            words)
          </p>
        )}
      </div>
    </div>
  );
};

AuthorTease.propTypes = {
  author: authorPropTypes,
  date: PropTypes.string,
  topic: topicPropTypes,
  readingTime: PropTypes.object,
};

export default AuthorTease;
