const BASE_PATHS = {
  home: '/',
  insights: 'insights',
  advisors: 'why-commonwealth/our-advisors',
  author: 'author',
  appearances: 'appearances',
  auth: 'communitylink',
};

module.exports = BASE_PATHS;
