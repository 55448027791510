import React from 'react';
import PropTypes from 'prop-types';

import ImageCTA from './ImageCTA';
import IconCTA from './IconCTA';

const ColumnCTA = ({ ctaType, ...props }) => {
  const mapping = {
    Image: ImageCTA,
    Icon: IconCTA,
  };

  const _component = mapping[ctaType] || ImageCTA;

  return <_component {...props} />;
};

ColumnCTA.propTypes = {
  ctaType: PropTypes.string,
};

export default ColumnCTA;
