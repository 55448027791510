import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import { ArticleGrid } from 'components';
import styles from './columnCTA.module.scss';

const ColumnCTA = props => {
  const { title, items } = props;
  if (!items.length) {
    return null;
  }

  const list = items.map(el => ({
    ...el,
    author: null,
    intro: {
      intro: el?.intro,
    },
    __typename: el?.ctaType ? 'ContentfulColumnCtaItem' : 'ContentfulArticle',
  }));

  return (
    <div
      {...className(styles.relatedArticleFooter, styles.contentWrapper, 'component')}>
      {title && <h3>{title}</h3>}
      <ArticleGrid
        articles={list}
        className={styles.relatedArticleFooterGrid}
        relatedArticle
      />
    </div>
  );
};

ColumnCTA.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

export default ColumnCTA;

