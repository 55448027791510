import React from 'react';
import PropTypes from 'prop-types';
import { className, renderText, safeGet, getQueryParams } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import styles from './textWidth.module.scss';
import { Link } from 'components';

const TextWidth = ({ caption, media, link, className: customClassName }) => {
  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    media,
    [0, 'title'],
    [0, 'description'],
    [0, 'file', 'url'],
  );

  if (!imageUrl) {
    return null;
  }

  const imageContent = (
    <div className={styles.imageWrap}>
      <div className={styles.clippingBox}>
        <img
          src={`${imageUrl}${getQueryParams({ w: 1200 })}`}
          alt={imageDescription || imageTitle}
        />
      </div>
    </div>
  );

  const imageComponent = link ? (
    <Link className={styles.linkWrapper} to={link}>
      {imageContent}
    </Link>
  ) : (
    imageContent
  );

  return (
    <div {...className(customClassName, 'component', 'media-text-width', styles.textWidth)}>
      <figure className={styles.figure}>
        {imageComponent}
        {caption && (
          <figcaption className={styles.caption}>{renderText({ json: caption })}</figcaption>
        )}
      </figure>
    </div>
  );
};

TextWidth.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.object,
  media: PropTypes.arrayOf(imagePropTypes),
  link: PropTypes.string,
};

export default TextWidth;