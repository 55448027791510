import * as THREE from 'three';
import CanvasObject from './canvas-object';
import { TweenMax, Power2 } from 'gsap';
import { CANVAS_ANIMATION_DURATION } from 'utils/constants';

class CanvasStripe extends CanvasObject {
  constructor(d, r) {
    super(d);

    //does it rotate?
    this.r = r;

    //index
    this.i = this.dom.getAttribute('data-index');

    //get the speed delta and segment width from the DOM attribute
    this.delta = parseInt(this.dom.getAttribute('data-delta')) / 100;
    this.ratio = parseInt(this.dom.getAttribute('data-ratio')) / 100;

    //resize the stripe based on the segment width
    this.mesh.scale.x /= this.r ? 1 : this.ratio;
    this.mesh.scale.x *= this.r ? 2 : 1;
    this.mesh.scale.z = 100;

    this.rotation = { x: 0, y: 0, z: 0 };

    this.opacity = 0;

    this.mesh.material.uniforms['vOpacity'].value = 0;

    if (this.r) {
      this.mesh.material.uniforms['crop'].value = 1;
    }

    this.mesh.material.depthTest = false;

    this.progress = 0;

    this.y_ = 0;

    this.box = this.dom.getBoundingClientRect();

    this.exit = false;
  }

  resize() {
    super.resize();
    this.box = this.dom.getBoundingClientRect();
    this.mesh.scale.x /= this.r ? 1 : this.ratio;
    this.mesh.scale.x *= this.r ? 2 : 1;
  }

  in() {
    TweenMax.to(this, CANVAS_ANIMATION_DURATION, {
      opacity: 1,
      ease: Power2.easeOut,
      delay: (this.i - 1) * 0.2,
    });
    TweenMax.fromTo(
      this.rotation,
      CANVAS_ANIMATION_DURATION,
      { x: 5 * THREE.Math.DEG2RAD, y: this.r ? -40 * THREE.Math.DEG2RAD : 0 },
      {
        x: this.r ? -2 * THREE.Math.DEG2RAD : 0,
        y: this.r ? -60 * THREE.Math.DEG2RAD : 0,
        ease: Power2.easeOut,
        delay: (this.i - 1) * 0.2,
      },
    );
    TweenMax.fromTo(
      this,
      CANVAS_ANIMATION_DURATION,
      { y_: window.innerHeight * -0.2 },
      { y_: 0, ease: Power2.easeOut, delay: (this.i - 1) * 0.2 },
    );
  }

  inL() {
    TweenMax.to(this, 1.6, { opacity: 1, ease: Power2.easeOut, delay: (this.i - 1) * 0.2 });
    TweenMax.fromTo(
      this.rotation,
      1.6,
      { x: 5 * THREE.Math.DEG2RAD, y: this.r ? -20 * THREE.Math.DEG2RAD : 0 },
      {
        x: this.r ? -2 * THREE.Math.DEG2RAD : 0,
        y: this.r ? -60 * THREE.Math.DEG2RAD : 0,
        ease: Power2.easeOut,
        delay: (this.i - 1) * 0.2,
      },
    );
    TweenMax.fromTo(
      this,
      1.6,
      { y_: window.innerHeight * -0.2 },
      { y_: 0, ease: Power2.easeOut, delay: (this.i - 1) * 0.2 },
    );
    TweenMax.fromTo(
      this.mesh.material.uniforms['progress'],
      1.6,
      { value: 0 },
      { value: 1, ease: Power2.easeOut, delay: (this.i - 1) * 0.2 },
    );
  }

  out() {
    this.exit = true;
    TweenMax.to(this, 1, { opacity: 0, ease: Power2.easeIn });
    TweenMax.to(this.rotation, 1, {
      x: -10 * THREE.Math.DEG2RAD,
      y: this.r ? -40 * THREE.Math.DEG2RAD : 0,
      ease: Power2.easeIn,
      delay: (this.i - 1) * 0.1,
    });
    TweenMax.to(this, 0.8, {
      y_: window.innerHeight * 0.25,
      ease: Power2.easeIn,
      delay: (this.i - 1) * 0.1,
    });
  }

  rAF(x, y, p) {
    const dom = document.documentElement;

    this.mesh.position.y =
      -this.box.top - (this.box.height - dom.clientHeight) / 2 + this.y_ + p / 30;

    this.mesh.position.x =
      this.box.left +
      (this.box.width - dom.clientWidth) / 2 -
      (dom.clientWidth / 2 - dom.clientWidth / this.ratio / (!this.r ? 2 : 1));

    this.mesh.rotation.y =
      (this.r ? (-x / dom.clientWidth) * 6 * THREE.Math.DEG2RAD : 0) + this.rotation.y;
    this.mesh.rotation.x =
      (this.r
        ? ((-y / dom.clientHeight / 2) * (this.i - 1) - p / dom.clientHeight / this.i / 20) *
          THREE.Math.DEG2RAD
        : 0) + this.rotation.x;

    this.mesh.material.uniforms['vOpacity'].value =
      this.opacity - Math.min(Math.max(p / dom.clientHeight / 2, 0), 0.3);

    if (this.r) {
      this.mesh.material.uniforms['gradient'].value = 0.6 - x / dom.clientWidth / 10;
    }
  }
}

export default CanvasStripe;
