import React from 'react';
import PropTypes from 'prop-types';

const Checkmark = ({ className: customClassName }) => (
  <svg className={customClassName} viewBox="0 0 24 20">
    <g stroke="none" fill="none" fillRule="evenodd">
      <polyline
        stroke="currentColor"
        strokeWidth="3"
        points="2 10.888277 8.1369515 17 22 2"></polyline>
    </g>
  </svg>
);

Checkmark.propTypes = {
  className: PropTypes.string,
};

export default Checkmark;
