import React from 'react';
import { Link, PullQuote } from 'components';
import PropTypes from 'prop-types';
import styles from './recircList.module.scss';
import { BASE_PATHS, className, isNonEmptyArray, getQueryParams, safeGet } from 'utils';

const RecircList = ({
  headline,
  overline,
  listItems,
  buttons,
  format,
  type,
  sidebarBlockquote,
  className: customClassName,
}) => {
  const title = overline ? overline : headline;
  const isFloatRight = format === 'floatRight' || type === 'Related Links - Sidebar';
  const isTeaser = format === 'teaser';
  const isDefault = format === false || format === undefined;

  const {
    content,
    showQuotes,
    type: pullQuoteType,
    attribution,
    attributionSecondLine,
    attributionLink,
    attributionThumbnail,
    image,
  } = safeGet(
    sidebarBlockquote,
    'content',
    'showQuotes',
    'type',
    'attribution',
    'attributionSecondLine',
    'attributionLink',
    'attributionThumbnail',
    'image',
  );

  return (
    <section
      {...className(
        'component',
        styles.wrapper,
        isFloatRight && styles.floatRight,
        isDefault && styles.default,
        isTeaser && styles.teaser,
      )}>
      <div {...className(
        styles.bodyWrapper,
        customClassName,
      )}>
        {title && title.length > 0 && <h1 className={styles.overline}>{title}</h1>}
        <ul className={styles.recircList}>
          {isNonEmptyArray(listItems) &&
              listItems.map((item, i) => {
                const link = item.slug.startsWith('http')
                  ? item.slug
                  : `/${BASE_PATHS.insights}/${item.slug}`;
                const [year, month, day] = item.date.split('-');

                const getImage = () => {
                  const imageUrl =
                    item.articleImage?.file.url || item.topics?.[0].image?.file?.url || null;
                  return imageUrl;
                };

                return (
                  <li className={styles.item} key={i}>
                    {getImage() && (
                      <Link className={styles.itemImage} to={link}>
                        <img
                          alt=""
                          src={`${getImage()}${getQueryParams({
                            w: 140,
                            fit: 'fill',
                          })}`}
                        />
                      </Link>
                    )}
                    <div className={styles.itemText}>
                      <p className={styles.itemDate}>
                        {month}.{day}.{year.slice(-2)}
                      </p>
                      <Link to={link} className={styles.itemLink}>
                        <span className={styles.itemTitle}>{item.title}</span>
                      </Link>
                      {item.intro && <p className={styles.itemIntro}>{item.intro.intro}</p>}
                    </div>
                  </li>
                );
              })}
          {isNonEmptyArray(buttons) &&
              buttons.map((item, i) => {
                if (typeof window === `undefined`) {
                  return;
                }

                const onSamePath = item[1] === window.location.pathname;

                //if listItems has links, style as buttons (ex. appearances sidebar), otherwise style as links (ex. embedded recirc list)
                return isNonEmptyArray(listItems) ? (
                  <li key={i}>
                    <Link to={item[1]} disabled={onSamePath} className={styles.button}>
                      {item[0]}
                    </Link>
                  </li>
                ) : (
                  <li className={styles.item} key={i}>
                    <Link
                      to={item[1]}
                      {...className(styles.itemLink, onSamePath && styles.itemLinkActive)}
                      disabled={onSamePath}>
                      <p className={styles.itemTitle}>{item[0]}</p>
                    </Link>
                  </li>
                );
              })}
        </ul>
      </div>
      {sidebarBlockquote && (
        <div className={styles.blockquoteWrapper}>
          <PullQuote
            content={content}
            withQuotes={showQuotes}
            attribution={attribution}
            attributionSecondLine={attributionSecondLine}
            attributionLink={attributionLink}
            attributionThumbnail={attributionThumbnail}
            image={image}
            type={pullQuoteType}
          />
        </div>

      )}
    </section>
  );
};

RecircList.propTypes = {
  headline: PropTypes.string,
  overline: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      date: PropTypes.date,
    }),
  ),
  buttons: PropTypes.arrayOf(PropTypes.array),
  format: PropTypes.string,
  type: PropTypes.string,
  sidebarBlockquote: PropTypes.object,
  className: PropTypes.object,
};

export default RecircList;
