import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import BASE_PATHS from 'utils/paths';
import { className } from 'utils';
import { topicPropTypes } from 'utils/proptypes';
import styles from './topicList.module.scss';

const TopicList = ({ className: customClassName, topics }) => (
  <section {...className(customClassName, styles.topicListOuter)}>
    <div className={styles.topicListInner}>
      <h2 className={styles.heading}>Browse by Topic</h2>
      <ul>
        {topics.map(({ name, slug }, i) =>
          name && slug ? (
            <li className={styles.topicItem} key={i}>
              <Link className={styles.topicLink} to={`/${BASE_PATHS.insights}/${slug}`}>
                {name}
              </Link>
            </li>
          ) : null,
        )}
      </ul>
    </div>
  </section>
);

TopicList.propTypes = {
  className: PropTypes.string,
  topics: PropTypes.arrayOf(topicPropTypes),
};

export default TopicList;
