import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import FocusTrap from 'focus-trap-react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import get from 'lodash.get';
import tabbable from 'tabbable';
import {
  Link,
  Navigation,
  Footer,
  Loading,
  Pathways,
  ScrollToTop,
  TwitterPixel,
  LinkedinPixel,
  VWO,
} from 'components';
import { TweenMax, Power1, Power3 } from 'gsap';
import { Hamburger, SearchIcon } from 'svgs';
import { className } from 'utils';
import { useDetectSSR } from 'hooks';
import {
  KEY_CODES,
  AnimationContext,
  CANVAS_ANIMATION_DURATION,
  BlankTemplateContext,
} from 'utils/constants';
import styles, { varBpNav } from './layout.module.scss';
import searchIconBaseStyles from '../Navigation/Desktop/desktop.module.scss';
import Canvas from 'threejs/canvas';
import { navAnimation } from 'threejs/page';
import HubspotPixel from '../Pixels/Hubspot';
import FontawesomePixel from '../Pixels/Fontawesome';

const Layout = ({ children, location }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          netlifyDeployPrimeUrl
          netlifyBranch
        }
      }
    }
  `);

  const VAR_BP_NAV = parseInt(varBpNav);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [scrollLock, setScrollLock] = useState(false);
  const [isIE, setIsIE] = useState(false);
  const [isBlankTemplate, setIsBlankTemplate] = useState(false);
  const navigationRef = useRef();
  const canvasRef = useRef();
  const loadingRefs = useRef();
  const isSSR = useDetectSSR();

  const skipToContent = () => {
    const main = get(document.getElementsByTagName('main'), 0);

    if (main) {
      const firstTabbable = get(tabbable(main), 0);

      if (firstTabbable) {
        firstTabbable.focus();
      }

      main.scrollIntoView();
    }
  };

  const closeMenuOnEsc = event => {
    if (event.keyCode === KEY_CODES.ESCAPE) {
      setIsMobileMenuOpen(false);
    }
  };

  const closeMenuOnResize = () => {
    if (window.innerWidth > VAR_BP_NAV) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    isMobileMenuOpen
      ? disableBodyScroll(navigationRef.current)
      : enableBodyScroll(navigationRef.current);

    return () => clearAllBodyScrollLocks();
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const time = isIE ? 0 : CANVAS_ANIMATION_DURATION * 1000;
    setTimeout(() => {
      setIsMobileMenuOpen(false);
    }, time);
  }, [location]);

  const rAF = () => {
    canvasRef.current.rAF();
    requestAnimationFrame(rAF);
  };

  const initializeAnimation = showLoading => {
    canvasRef.current = new Canvas();
    setScrollLock(true);

    if (showLoading) {
      TweenMax.set(loadingRefs.current.loadingScreen, { force3D: true });
      TweenMax.to([loadingRefs.current.stripeB, loadingRefs.current.stripeF], 0.2, {
        autoAlpha: 1,
      });
      TweenMax.to(loadingRefs.current.stripeF, 0.4, { scaleX: 0.8 });

      //once we finish our canvas init, we call a request animation frame function to generate the animation
      canvasRef.current.init().then(() => {
        TweenMax.to(loadingRefs.current.stripeF, 0.2, {
          scaleX: 1,
          delay: 0.4,
          onComplete: () => {
            TweenMax.to([loadingRefs.current.stripeB, loadingRefs.current.stripeF], 0.5, {
              scaleX: 0,
              delay: 0.1,
              ease: Power3.easeInOut,
              transformOrigin: '100% 0%',
            });
            TweenMax.to(loadingRefs.current.loadingScreen, 1, {
              autoAlpha: 0,
              ease: Power1.easeIn,
              onComplete: () => setScrollLock(false),
            });

            requestAnimationFrame(rAF);
            navAnimation();
            canvasRef.current.inL();
          },
        });
      });
    } else {
      canvasRef.current.init().then(() => {
        setScrollLock(false);
        requestAnimationFrame(rAF);
        navAnimation();
        canvasRef.current.inL();
      });
    }
  };

  const checkIsIE = () => ['MSIE', 'Trident/'].some(string => navigator.userAgent.match(string));

  useEffect(() => {
    document.addEventListener('keydown', closeMenuOnEsc);
    window.addEventListener('resize', closeMenuOnResize);

    const isIE = checkIsIE();

    if (!isSSR) {
      setIsIE(isIE);
    }

    if (!isIE) {
      const showLoading = location.pathname === '/';
      initializeAnimation(showLoading);
    }

    return () => {
      document.removeEventListener('keydown', closeMenuOnEsc);
      window.removeEventListener('resize', closeMenuOnResize);
    };
  }, []);

  return (
    <AnimationContext.Provider value={{ canvasRef, isMobileMenuOpen }}>
      <BlankTemplateContext.Provider value={{ isBlankTemplate, setIsBlankTemplate }}>
        <div
          id="top"
          {...className(
            styles.layoutWrapper,
            isMobileMenuOpen && styles.menuOpen,
            isMobileMenuOpen && 'menu-open-global',
            isIE && 'is-ie',
            scrollLock && 'no-scroll',
          )}>
          <aside aria-label="Skip to Content">
            <button className={styles.skipToContentButton} onClick={skipToContent}>
              Skip to Content
            </button>
          </aside>
          <FocusTrap
            active={isMobileMenuOpen}
            focusTrapOptions={{ escapeDeactivates: false, clickOutsideDeactivates: true }}>
            <Navigation
              siteTitle={site.siteMetadata.title}
              isMobileMenuOpen={isMobileMenuOpen}
              ref={navigationRef}
            />
          </FocusTrap>
          {location.pathname !== '/' && <ScrollToTop />}
          <main className={styles.pageContent}>{children}</main>
          <Footer className={styles.footer} />
          {!isBlankTemplate && (
            <nav>
              {isMobileMenuOpen && (
                <Link to="/search" {...className(styles.menuLink, styles.menuSearch)}>
                  <SearchIcon
                    {...className(searchIconBaseStyles.SearchIcon, styles.menuSearchIcon)}
                  />
                </Link>
              )}
              <button
                className={styles.menuButton}
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                aria-label={isMobileMenuOpen ? 'Close Menu' : 'Open Menu'}>
                <Hamburger className={styles.menuIcon} />
                <span className={styles.visuallyHidden}>Menu</span>
              </button>
            </nav>
          )}
          <div role="region" aria-label="Secondary Content">
            <Loading ref={loadingRefs} type="Screen" />
            <Pathways />
            <div id="canvas" className={styles.canvas} />
          </div>
        </div>
        <TwitterPixel />
        <LinkedinPixel />
        <HubspotPixel />
        <VWO />
        <FontawesomePixel />
      </BlankTemplateContext.Provider>
    </AnimationContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
