import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './stickyNavigation.module.scss';
import { className, toVariableName } from '../../utils';
import DropdownMenu from './DropdownMenu';

const StickyNavigation = ({ title, headline, menus }) => {
  const [stuck, setStuck] = useState(false);
  const stickyRef = useRef(null);

  useEffect(() => {
    const stickyNav = stickyRef?.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        const isStuck = e.intersectionRatio > 0;
        setStuck(isStuck);
      },
      { threshold: [0], root: null, rootMargin: '0px 0px -99% 0px' },
    );

    stickyNav && observer.observe(stickyNav);

    return () => {
      observer.unobserve(stickyNav);
    };
  }, [stickyRef]);

  return (
    <div
      data-automation-id={toVariableName(title)}
      ref={stickyRef}
      {...className('component', styles.stickyNavigation, stuck ? styles.stuck : '')}>
      {headline && <h3 className={styles.navTitle}>{headline}</h3>}
      <div className={styles.menuSection}>
        {menus.map(mainMenu => {
          if (mainMenu.buttonLabel) {
            return (
              <Link className={styles.mainLink} key={mainMenu.buttonLabel} to={mainMenu.buttonLink}>
                {mainMenu.buttonLabel}
              </Link>
            );
          }
          return <DropdownMenu key={mainMenu.heading} menu={mainMenu} />;
        })}
      </div>
    </div>
  );
};
StickyNavigation.propTypes = {
  headline: PropTypes.string,
  menus: PropTypes.array,
  title: PropTypes.string,
};

export default StickyNavigation;
