import React from 'react';
import PropTypes from 'prop-types';
import { safeGet, className, getQueryParams, isNonEmptyArray, renderText } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import { Link } from 'components';
import styles from './footerCta.module.scss';

const Footer = ({ content, headline, image, signature, buttons, type, showWhiteArea }) => {
  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    image,
    'title',
    'description',
    ['file', 'url'],
  );
  const {
    title: signatureTitle,
    content: signatureDescription,
    url: signatureUrl,
  } = safeGet(signature, 'title', 'description', ['file', 'url']);
  //check if there's only one button
  const relatedLinks = type.includes('Related Links');

  return (
    <div
      {...className(
        'component',
        'page-left-inset',
        styles.outerWrapper,
        showWhiteArea && styles.wrapperWithArea,
      )}>
      <div className={styles.contentWrapper}>
        {imageUrl && (
          <div className={styles.images}>
            <img
              className={styles.circularImage}
              src={`${imageUrl}${getQueryParams({ w: 400, h: 400, fit: 'fill', f: 'face' })}`}
              alt={imageDescription || imageTitle}
            />
            <img
              className={styles.signatureImage}
              src={signatureUrl}
              alt={signatureDescription || signatureTitle}
            />
          </div>
        )}
        <div className={styles.copy}>
          {headline && <h1 className={styles.headline}>{headline}</h1>}
          {content && <div className={styles.description}>{renderText(content)}</div>}
          {buttons && isNonEmptyArray(buttons) && (
            <div className={styles.buttonWrapper}>
              {buttons.map((item, i) => (
                <Link
                  {...className(styles.button, relatedLinks && styles.buttonRelated)}
                  to={item.content[1]}
                  key={i}
                  newTab>
                  {item.content[0]}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  content: PropTypes.object,
  headline: PropTypes.string,
  image: imagePropTypes,
  signature: imagePropTypes,
  buttons: PropTypes.array,
  type: PropTypes.string,
  showWhiteArea: PropTypes.bool,
};

Footer.defaultProps = {
  showWhiteArea: true,
};

export default Footer;
