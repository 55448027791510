import React from 'react';
import PropTypes from 'prop-types';
import { BASE_PATHS, getQueryParams, safeGet, isNonEmptyArray } from 'utils';
import { topicPropTypes } from 'utils/proptypes';
import { Carousel, Link } from 'components';
import styles from './topicsCarousel.module.scss';

const TopicsCarousel = ({ topics }) => {
  const VAR_BP_SM = parseInt(styles.varBpSm);
  const VAR_BP_MD_SM = parseInt(styles.varBpMdSm);
  const VAR_BP_XL = parseInt(styles.varBpXl);

  const options = {
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: VAR_BP_XL,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: VAR_BP_MD_SM,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: VAR_BP_SM,
        settings: {
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Carousel title="Featured Topics" className={styles.topicsCarousel} customOptions={options}>
      {isNonEmptyArray(topics) &&
        topics.map(topic => {
          const {
            title: imageTitle,
            description: imageDescription,
            url: imageUrl,
          } = safeGet(topic.image, 'title', 'description', ['file', 'url']);
          return (
            imageUrl && (
              <figure className={styles.topicSlide} key={topic.slug}>
                <Link to={`/${BASE_PATHS.insights}/${topic.slug}`} className={styles.linkWrapper}>
                  <div className={styles.topicImageWrapper}>
                    <div className={styles.clippingBox}>
                      <img
                        className={styles.topicImage}
                        src={`${imageUrl}${getQueryParams({
                          w: 600,
                          h: 850,
                          fit: 'fill',
                        })}`}
                        alt={imageDescription || imageTitle}
                      />
                    </div>
                  </div>
                  <figcaption>
                    <h2 className={styles.topicName}>{topic.name}</h2>
                  </figcaption>
                </Link>
              </figure>
            )
          );
        })}
    </Carousel>
  );
};

TopicsCarousel.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  topics: PropTypes.arrayOf(topicPropTypes),
};

export default TopicsCarousel;
