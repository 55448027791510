import React from 'react';
import Helmet from 'react-helmet';

const HubspotPixel = () => (
  <Helmet>
    {/* Start of HubSpot Embed Code */}
    <script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js.hs-scripts.com/295024.js"></script>
    {/* End of HubSpot Embed Code */}
  </Helmet>
);

export default HubspotPixel;
