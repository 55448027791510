import React from 'react';
import { className, isNonEmptyArray } from 'utils';
import styles from './solutionColumns.module.scss';
import { ButtonLink, Link } from 'components';
import PropTypes from 'prop-types';
import WideRightArrow from '../../../../svgs/Arrow/WideRight';

const SolutionColumns = ({ solutions }) => {
  const columnData = [1, 2, 3]
    .map(index => ({
      intro: solutions[`intro${index}`][`intro${index}`],
      headline: solutions[`headline${index}`],
      linkText: solutions[`linkText${index}`],
      linkUrl: solutions[`linkUrl${index}`],
      linkType: solutions[`linkType${index}`],
    }));

  return (
    <ul {...className(styles.wrapper, styles.backsplash)}>
      {isNonEmptyArray(columnData) &&
        columnData.map((data, i) => (
          <li key={`key_${i}`} {...className(styles.item)}>
            <div className={styles.itemInner}>
              <div className={styles.text}>
                <h1 className={styles.title}>
                  {data.headline}
                </h1>
                <p className={styles.intro}>
                  {data.intro}
                </p>
                {data.linkType === 'Button' ? (
                  <ButtonLink className={styles.buttonLink} buttonLink={data.linkUrl} buttonLabel={data.linkText} />
                ) : (
                  <Link to={data.linkUrl} {...className('button-link', styles.textLink)}>
                    <span className={styles.buttonLink}>{data.linkText}</span>
                    <WideRightArrow />
                  </Link>
                )}
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
};

SolutionColumns.propTypes = {
  solutions: PropTypes.object,
};

export default SolutionColumns;
