import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './offering.module.scss';

const Offering = ({ slug, url, number, title, tease }) => (
  <Link className={styles.linkedWrapper} to={(slug && `/${slug}`) || url}>
    <div className={styles.contentWrapper}>
      <span className={styles.numberWrapper}>
        <span className={styles.number}>{number.toString().padStart(2, '0')}</span>
        <span className={styles.arrow}>&#8599;</span>
      </span>
      <h1 className={styles.title}>{title}</h1>
      {tease && <div className={styles.content}>{tease}</div>}
    </div>
  </Link>
);

Offering.propTypes = {
  slug: PropTypes.string,
  url: PropTypes.string,
  number: PropTypes.number,
  title: PropTypes.string,
  tease: PropTypes.string,
};

export default Offering;
