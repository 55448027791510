import PropTypes from 'prop-types';
const { array, shape, string, number, object, oneOfType } = PropTypes;

export const imagePropTypes = shape({
  title: string.isRequired,
  file: shape({
    url: string.isRequired,
  }),
});

export const videoPropTypes = shape({
  file: shape({
    url: string.isRequired,
  }),
});

export const authorPropTypes = shape({
  name: string.isRequired,
  title: string,
  description: shape({
    description: string.isRequired,
  }),
  image: imagePropTypes,
});

export const topicPropTypes = shape({
  name: string.isRequired,
  slug: string,
  image: imagePropTypes,
});

export const ctaPropTypes = shape({
  ctaType: string.isRequired,
  headline: string,
  buttonText: string,
  buttonLink: string,
  hubspotFormId: string,
  description: oneOfType([string, object]),
  image: imagePropTypes,
});

export const articleStubPropTypes = shape({
  title: string.isRequired,
  intro: shape({
    intro: string.isRequired,
  }),
  topic: topicPropTypes,
  author: authorPropTypes,
  slug: PropTypes.string.isRequired,
  featuredImage: imagePropTypes,
});

export const articlePropTypes = shape({
  title: string.isRequired,
  intro: shape({
    intro: string.isRequired,
  }).isRequired,
  topic: topicPropTypes,
  author: authorPropTypes,
  date: string.isRequired,
  content: object.isRequired,
  cta: ctaPropTypes,
  seo: object,
  featuredImage: imagePropTypes,
  featuredArticles: array,
});

export const menuPropTypes = shape({
  headline: string,
  headlineUrl: string,
  content: object,
});

export const locationPropTypes = shape({
  branch: string.isRequired,
  firstAddressLine: string.isRequired,
  secondAddressLine: string.isRequired,
  tollFreeNumber: number,
  phoneNumber: number,
  faxNumber: number,
  image: imagePropTypes,
});

export const offeringPropTypes = shape({
  slug: string,
  url: string,
  title: string,
  tease: string,
});

export const carouselItemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    image: imagePropTypes,
    caption: PropTypes.object,
    link: PropTypes.string,
  }),
);
