import React from 'react';
import PropTypes from 'prop-types';
import { className, safeGet } from 'utils';
import { Link } from 'components';
import styles from './authorLandingTopper.module.scss';

const AuthorLandingTopper = ({ description, title, name, linkText, linkUrl, image }) => {
  const { description: imageDescription, url: imageUrl } = safeGet(image, 'title', 'description', [
    'file',
    'url',
  ]);

  return (
    <header className="page-left-inset page-left-inset-topper">
      <div {...className(styles.topperWrapper, imageUrl && styles.withHangingImage)}>
        <section className={styles.headerWrapper}>
          {name && (
            <h1 className={styles.title}>
              {name}
              {title ? `, ${title}` : ''}
            </h1>
          )}
          {imageUrl && (
            <div className={styles.authorLockup}>
              <img className={styles.authorImg} src={imageUrl} alt={imageDescription} />
              {description && (
                <div {...className(styles.authorNameDate, styles.intro)}>
                  <div className={styles.authorDescription}>{description.description}</div>
                  {linkText && (
                    <Link className={styles.authorAdditionalLink} to={linkUrl}>
                      {linkText}
                    </Link>
                  )}
                </div>
              )}
            </div>
          )}
        </section>
      </div>
    </header>
  );
};

AuthorLandingTopper.propTypes = {
  name: PropTypes.string,
  intro: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.object,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  image: PropTypes.object,
};

export default AuthorLandingTopper;
