import * as THREE from 'three';
import { TweenMax } from 'gsap';
import { vertex, fragment } from './shaders';

class CanvasObject {
  constructor(d) {
    //set the DOM that is associated to this mesh object
    this.dom = d;

    //get the element colour from the DOM attribute
    this.color = parseInt(this.dom.getAttribute('data-color'));

    //create a geometry, material
    this.geo = new THREE.PlaneGeometry(1, 1, 1, 1);
    this.mat = this.createMat();

    //combine the geo and mat together to make a mesh
    this.mesh = new THREE.Mesh(this.geo, this.mat);

    //set the mesh's size to match our DOM element's size
    this.mesh.scale.set(this.dom.clientWidth, this.dom.clientHeight, 1);

    //hide the DOM to speed up the rendering
    TweenMax.set(this.dom, { force3D: true, autoAlpha: 0 });
  }

  //create and return our shader material
  createMat() {
    return new THREE.ShaderMaterial({
      uniforms: {
        vOpacity: { type: 'f', value: 1 },
        crop: { type: 'f', value: 0 },
        progress: { type: 'f', value: 1 },
        gradient: { type: 'f', value: 1 },
        color: { value: new THREE.Color(this.color) },
      },
      side: THREE.FrontSide,
      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
    });
  }

  in() {}

  out() {}

  resize() {
    this.mesh.scale.set(this.dom.clientWidth, this.dom.clientHeight, 1);
  }

  rAF() {
    //re-calculate the position
    const box = this.dom.getBoundingClientRect();
    this.mesh.position.y = -box.top - (box.height - window.innerHeight) / 2;
    this.mesh.position.x = box.left + (box.width - window.innerWidth) / 2;
  }
}

export default CanvasObject;
