import React from 'react';
import PropTypes from 'prop-types';

const EmailLogo = ({ className: customClassName }) => (
  <svg className={customClassName} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="currentColor"/>
    <path d="M5.21875 6C4.5459 6 4 6.50391 4 7.125C4 7.47891 4.18027 7.81172 4.4875 8.025L10.0125 11.85C10.302 12.0492 10.698 12.0492 10.9875 11.85L16.5125 8.025C16.8197 7.81172 17 7.47891 17 7.125C17 6.50391 16.4541 6 15.7812 6H5.21875ZM4 8.625V13.5C4 14.3273 4.72871 15 5.625 15H15.375C16.2713 15 17 14.3273 17 13.5V8.625L11.475 12.45C10.8961 12.8508 10.1039 12.8508 9.525 12.45L4 8.625Z" fill="#304A3D"/>
  </svg>
);

EmailLogo.propTypes = {
  className: PropTypes.string,
};

export const EmailWhiteLogo = ({ className: customClassName }) => (
  <svg className={customClassName} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="currentColor"/>
    <path d="M5.21875 6C4.5459 6 4 6.50391 4 7.125C4 7.47891 4.18027 7.81172 4.4875 8.025L10.0125 11.85C10.302 12.0492 10.698 12.0492 10.9875 11.85L16.5125 8.025C16.8197 7.81172 17 7.47891 17 7.125C17 6.50391 16.4541 6 15.7812 6H5.21875ZM4 8.625V13.5C4 14.3273 4.72871 15 5.625 15H15.375C16.2713 15 17 14.3273 17 13.5V8.625L11.475 12.45C10.8961 12.8508 10.1039 12.8508 9.525 12.45L4 8.625Z" fill="white"/>
  </svg>
);

EmailWhiteLogo.propTypes = {
  className: PropTypes.string,
};

export default EmailLogo;
