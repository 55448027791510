import get from 'lodash.get';
import { CURRENT_LANGUAGE } from './constants';

const isObject = value => typeof value === 'object';
const isStrictlyObject = value => isObject(value) && !value.length;
const { isArray } = Array;
export const isNonEmptyArray = value => isArray(value) && !!value.length;

const getQueryKey = query => (isNonEmptyArray(query) ? query[query.length - 1] : query);

const updateAccWithQuery = (acc, from, query, options = {}) => {
  const { defaultValue, name } = options;
  const value = get(from, query, defaultValue);
  const queryKey = name || getQueryKey(query);

  return value !== undefined ? { ...acc, [queryKey]: value } : acc;
};

/**
 * Safe get values from given object and return
 * a mapping from each attribute to value.
 *
 * @param {object} from the object to grab values from
 * @param {string[]|array[]|Query[]} queries the values to grab
 *
 * where Query is {
 *    query: string | string[]
 *    name: string
 *    defaultValue: Any
 * }
 */
export const safeGet = (from, ...queries) => {
  const result = queries.reduce((acc, query) => {
    if (isStrictlyObject(query)) {
      const { query: attr, name, defaultValue } = query;
      return updateAccWithQuery(acc, from, attr, { defaultValue, name });
    } else {
      return updateAccWithQuery(acc, from, query);
    }
  }, {});

  return result;
};

/**
 *
 * @param {Object} from | the object to grab values from
 * @param  {string[]|array[]|Query[]} queries | the values to grab
 *
 * This function does the same thing as `safeGet` but instead
 * returns any value that is scoped under the current language
 * i.e. 'en-US'
 */
export const safeGetForLanguage = (from, ...queries) => {
  const { ...result } = safeGet(from, ...queries);
  Object.keys(result).forEach(key => {
    if (result[key][CURRENT_LANGUAGE]) {
      result[key] = result[key][CURRENT_LANGUAGE];
    }
  });
  return result;
};

/**
 * Remove all top level and nested exclude fields.
 */
export const serialize = (obj, exclude = [CURRENT_LANGUAGE, 'fields']) => {
  if (!isObject(obj) || obj === null || obj === undefined) {
    return obj;
  }

  if (isArray(obj)) {
    return obj.map(el => serialize(el, exclude));
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (exclude.includes(key)) {
      return serialize(value, exclude);
    }

    const matchedExclude = exclude.find(key => get(value, key));
    const newValue = serialize(get(value, matchedExclude) || value, exclude);

    return { ...acc, [key]: newValue };
  }, {});
};

/**
 * Safe get after serializing given object by
 * removing top level and nested exclude fields
 */
export const bypassSafeGet = (from, exclude, ...queries) => {
  const serialized = serialize(from, exclude);
  return safeGet(serialized, ...queries);
};

export const bypassRichTextSafeGet = (from, ...queries) =>
  bypassSafeGet(from, [CURRENT_LANGUAGE, 'fields'], ...queries);

export const getQueryParams = obj =>
  Object.entries(obj).reduce((acc, [key, value]) => `${acc}${key}=${value}&`, '?');
