import React, { forwardRef, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import {
  className,
  createGoogleMapsLink,
  formatPhoneNumber,
  renderText,
  isNonEmptyArray,
} from 'utils';
import { BlankTemplateContext } from 'utils/constants';
import {
  Wordmark,
  FacebookLogo,
  InstagramLogo,
  LinkedInLogo,
  TwitterLogo,
  YoutubeLogo,
} from 'svgs';
import { Link, SocialLink } from 'components';
import styles from './footer.module.scss';
import { useArrayRef, useLinks } from 'hooks';
import { useMenuItems } from '../Navigation';

const Location = (footerAddress, i) => {
  const {
    branch,
    firstAddressLine,
    secondAddressLine,
    tollFreeNumber,
    phoneNumber,
    faxNumber,
  } = footerAddress;
  const fullAddress = `${firstAddressLine} ${secondAddressLine}`;

  return (
    <div key={i} className={styles.address}>
      <h1>{branch}</h1>
      <Link className={styles.addressLink} to={createGoogleMapsLink(fullAddress)} newTab>
        {firstAddressLine}, {secondAddressLine}
      </Link>
      {tollFreeNumber && (
        <p>
          <span>Toll-Free </span>
          <a href={`tel:+1${tollFreeNumber}`}>{formatPhoneNumber(tollFreeNumber)}</a>
        </p>
      )}
      {phoneNumber && (
        <p>
          <span>Phone </span>
          <a href={`tel:+1${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
        </p>
      )}
      {faxNumber && (
        <p>
          <span>Fax </span>
          <a href={`tel:+1${faxNumber}`}>{formatPhoneNumber(faxNumber)}</a>
        </p>
      )}
    </div>
  );
};

const Footer = forwardRef(({ className: customClassName, noFocus = false }, ref) => {
  const { isBlankTemplate } = useContext(BlankTemplateContext);

  const {
    global: {
      linkedIn,
      twitterHandle,
      facebook,
      youtube,
      instagram,
      footerLinksLeft,
      footerLinksRight,
      footerButtonText,
      footerButtonLink,
      brokerCheckImg,
      brokerCheckText,
      subFooter,
      footerAddresses,
      menu,
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      global: contentfulGlobal {
        menu {
          heading
          headingUrl
          dropdownHeading
          menuFormatType
          list {
            json
          }
        }
        linkedIn
        twitterHandle
        facebook
        youtube
        instagram
        footerLinksLeft {
          json
        }
        footerLinksRight {
          json
        }
        footerButtonText
        footerButtonLink
        brokerCheckImg {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
          description
        }
        brokerCheckText {
          json
        }
        subFooter {
          json
        }
        footerAddresses {
          branch
          firstAddressLine
          secondAddressLine
          tollFreeNumber
          phoneNumber
          faxNumber
        }
      }
    }
  `);
  const footerLeftLinks = useLinks(footerLinksLeft);
  const footerRightLinks = useLinks(footerLinksRight);
  const location = useLocation();
  const navItemsWithSubMenus = menu.map(useMenuItems);

  const [menuHeadingsRef, setMenuHeadingsRef] = useArrayRef(); // eslint-disable-line

  const menuMarkup = linkedList =>
    isNonEmptyArray(linkedList) &&
    linkedList.map(({ text, to }, i) =>
      text.join('').trim() && to ? (
        <li key={i} className={styles.menuItem} ref={setMenuHeadingsRef(i)}>
          <Link className={styles.menuLink} to={to} noFocus={noFocus}>
            {text}
          </Link>
        </li>
      ) : null,
    );

  const logoHref = location.pathname === '/' ? '/#top' : '/';

  return (
    <footer {...className(customClassName, styles.footer)} ref={ref}>
      <div className="page-left-inset">
        <section className={styles.logoWrap}>
          <Link className={styles.wordmarkLink} to={logoHref}>
            <Wordmark className={styles.wordmark} />
            <span className={styles.visuallyHidden}>Commonwealth Logo</span>
          </Link>
        </section>
        {!isBlankTemplate && (
          <section className={styles.topfooterWrap}>
            {navItemsWithSubMenus.map(nav => {
              const { heading, headingUrl, links } = nav;

              return (
                <ul {...className(styles.navMenuList, styles.menuList)} key={heading}>
                  <li className={styles.menuItem}>
                    <Link className={styles.menuLink} to={headingUrl}>
                      {heading}
                    </Link>
                  </li>
                  {menuMarkup(links)}
                </ul>
              );
            })}

            <ul {...className(styles.menuListOne, styles.menuList)}>
              {menuMarkup(footerLeftLinks)}
              <li>
                <a
                  href={footerButtonLink}
                  className={styles.footerButton}
                  target="_blank"
                  rel="noreferrer">
                  {footerButtonText}
                </a>
              </li>
            </ul>
            {!!footerRightLinks?.length && (
              <ul className={styles.menuList}>{menuMarkup(footerRightLinks)}</ul>
            )}

            {isNonEmptyArray(footerAddresses) && footerAddresses.map(Location)}

            <section className={styles.socialIcons}>
              <ul>
                {linkedIn && (
                  <SocialLink
                    text="LinkedIn"
                    to={linkedIn}
                    logoSvg={LinkedInLogo}
                    noFocus={noFocus}
                  />
                )}
                {twitterHandle && (
                  <SocialLink
                    text="Twitter"
                    to={`https://twitter.com/${twitterHandle}`}
                    logoSvg={TwitterLogo}
                    noFocus={noFocus}
                  />
                )}
                {facebook && (
                  <SocialLink
                    text="Facebook"
                    to={facebook}
                    logoSvg={FacebookLogo}
                    noFocus={noFocus}
                  />
                )}
                {youtube && (
                  <SocialLink text="Youtube" to={youtube} logoSvg={YoutubeLogo} noFocus={noFocus} />
                )}
                {instagram && (
                  <SocialLink
                    text="Instagram"
                    to={instagram}
                    logoSvg={InstagramLogo}
                    noFocus={noFocus}
                  />
                )}
              </ul>
            </section>
          </section>
        )}
      </div>
      <div className={styles.subfooterBackground}>
        <section className={styles.subfooterWrap}>
          <div className="page-left-inset">
            <div className={styles.subfooterInner}>
              <div className={styles.brokerCheck}>
                <a
                  href="https://brokercheck.finra.org/"
                  className={styles.brokerCheckImgLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={brokerCheckImg.file.url}
                    width={brokerCheckImg.file.details.image.width}
                    height={brokerCheckImg.file.details.image.height}
                    alt={brokerCheckImg.description}
                  />
                </a>
                <div>{renderText(brokerCheckText)}</div>
              </div>
              <div>{renderText(subFooter)}</div>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
});

Footer.propTypes = {
  className: PropTypes.string,
  noFocus: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
};

Footer.displayName = 'Menu';

export default Footer;
