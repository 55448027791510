import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { className, renderText, safeGet, getQueryParams } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import styles from './bleedRight.module.scss';

const BleedRight = ({ caption, media, link, className: customClassName, imgWidth, imgHeight }) => {
  const { title, description, url } = safeGet(
    media,
    [0, 'title'],
    [0, 'description'],
    [0, 'file', 'url'],
  );

  if (!url) {
    return null;
  }

  const imageContent = (
    <img
      src={`${url}${getQueryParams({ w: imgWidth, h: imgHeight, fit: 'fill' })}`}
      alt={description || title}
    />
  );

  const imageComponent = link ? <Link to={link}>{imageContent}</Link> : imageContent;

  return (
    <figure {...className('component', customClassName, styles.bleedRight)}>
      <div className={styles.aspectRatioWrapper}>{imageComponent}</div>
      {caption && (
        <figcaption {...className(styles.caption, 'bleed-right-caption')}>
          {renderText({ json: caption })}
        </figcaption>
      )}
    </figure>
  );
};

BleedRight.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.object,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  media: PropTypes.arrayOf(imagePropTypes),
  link: PropTypes.string,
};

BleedRight.defaultProps = {
  imgHeight: 900,
  imgWidth: 600,
};

export default BleedRight;
