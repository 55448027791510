import PropTypes from 'prop-types';
import { renderHtmlToReact } from '../CustomRichText';

const InlineFontawesomeIcon = ({ html }) => {
  if (!html) {
    return null;
  }
  return renderHtmlToReact(html);
};

InlineFontawesomeIcon.propTypes = {
  html: PropTypes.string.isRequired,
};

export default InlineFontawesomeIcon;
