import React from 'react';
import PropTypes from 'prop-types';
import { safeGet, renderText, getQueryParams, className } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import { Link } from 'components';
import styles from './topperContentLeft.module.scss';

const TopperContentLeft = ({ intro, image, link }) => {
  const { title, description, url } = safeGet(image, 'title', 'description', ['file', 'url']);

  const imageContent = (
    <div className={styles.imageWrapper}>
      <div className={styles.clippingBox}>
        <img
          className={styles.image}
          alt={description || title}
          src={`${url}${getQueryParams({ w: 700, h: 900, fit: 'fill' })}`}
        />
      </div>
    </div>
  );

  const imageComponent = link ? (
    <Link className={styles.linkWrapper} to={link}>
      {imageContent}
    </Link>
  ) : (
    imageContent
  );

  return (
    <div {...className(styles.bigBlock, 'js-animate-profiles')}>
      {url && imageComponent}
      {intro && <div className={styles.text}>{renderText(intro)}</div>}
    </div>
  );
};

TopperContentLeft.propTypes = {
  intro: PropTypes.object,
  image: imagePropTypes,
  link: PropTypes.string,
};

export default TopperContentLeft;
