import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'components';
import styles from './404.module.scss';

const NotFound = () => (
  <Fragment>
    <Helmet
      bodyAttributes={{
        class: 'has-animation not-found',
      }}
    />
    <div className="page-left-inset">
      <div className={styles.pageWrapper}>
        <p className={styles.errorName}>
          <span>Error 404</span>
        </p>
        <h1 className={styles.errorTitle}>Sorry, there’s nothing here.</h1>
        <p className={styles.errorDescription}>
          The page you are looking for does not exist. The address may have been mistyped or the
          page may have moved.
        </p>
        <Link className={styles.homepageUrl} to="/">
          Return to the homepage <span>&rarr;</span>
        </Link>
      </div>
    </div>
  </Fragment>
);
export default NotFound;
