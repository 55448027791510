import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  AnimationContext,
  CANVAS_ANIMATION_DURATION,
  PAGE_ANIMATION_DURATION,
} from 'utils/constants';
import { useDetectIE } from 'hooks';
import PluginTransitionLink from 'gatsby-plugin-transition-link';
import { gsap } from 'gsap';
import { pageAnimationOut, pageAnimationIn } from 'threejs/page';

const TransitionLink = forwardRef(({ children, ...props }, ref) => {
  gsap.registerPlugin(require('gsap/ScrollToPlugin'));
  const { canvasRef, isMobileMenuOpen } = useContext(AnimationContext);
  const isIE = useDetectIE();

  return (
    <PluginTransitionLink
      exit={{
        trigger: async ({ node }) => {
          if (canvasRef.current) {
            await canvasRef.current.out();
          }

          if (!isIE) {
            pageAnimationOut(node, isMobileMenuOpen);
          }
          if (document.activeElement) {
            document.activeElement.blur();
          }
        },
        length: CANVAS_ANIMATION_DURATION + PAGE_ANIMATION_DURATION,
      }}
      entry={{
        trigger: ({ node }) => {
          if (canvasRef.current) {
            canvasRef.current.in();
          }

          if (!isIE) {
            const enterURL = new URL(node.baseURI);

            if (enterURL.pathname !== '/') {
              pageAnimationIn(node);
            }
          }
        },
        delay: CANVAS_ANIMATION_DURATION + PAGE_ANIMATION_DURATION,
        length: CANVAS_ANIMATION_DURATION + 0.1, // added 0.1 buffer to make sure entry always runs
      }}
      ref={ref}
      {...props}
      preventScrollJump>
      {children}
    </PluginTransitionLink>
  );
});

TransitionLink.displayName = 'TransitionLink';

TransitionLink.propTypes = {
  children: PropTypes.node,
};

export default TransitionLink;
