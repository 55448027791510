import React, { useState, useRef } from 'react';
import { Caret } from 'svgs';
import { className, renderText, toVariableName } from 'utils';
import PropTypes from 'prop-types';
import styles from './readMoreLess.module.scss';

const ReadMoreLess = ({ title, collapsed, content }) => {
  const contentWrapperRef = useRef();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(prev => !prev);
  };

  const id = toVariableName(title);

  return (
    <div
      {...className(
        'component-rich-text',
        styles.expandCollapse,
        isExpanded ? styles.expanded : styles.collapsed,
      )}>
      <div
        id={id}
        className={styles.contentWrapper}
        ref={contentWrapperRef}
        role="region"
        tabIndex="-1">
        <div className={styles.headerContent}>
          {renderText({ json: collapsed })}
          {!isExpanded && <span className={styles.ellipsis}>...</span>}
        </div>
        {content && <div className={styles.collapsedContent}>{renderText({ json: content })}</div>}
        <div
          className={styles.collapsedWrapper}
          onClick={handleClick}
          onKeyPress={handleClick}
          aria-controls={id}
          aria-expanded={isExpanded}
          tabIndex={0}
          role="button">
          <Caret className={styles.indicator} />
          <span className={styles.indicatorLabel}>{isExpanded ? 'Read Less' : 'Read More'}</span>
        </div>
      </div>
    </div>
  );
};

ReadMoreLess.propTypes = {
  title: PropTypes.string,
  collapsed: PropTypes.object.isRequired,
  content: PropTypes.object,
};

export default ReadMoreLess;
