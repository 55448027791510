import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isNonEmptyArray, parseShortText } from 'utils';
import styles from './bigList.module.scss';
import { Checkmark } from '../../svgs';

const BigList = ({ listItems, type }) => {
  const getItemPrefix = (type, i) => {
    const itemPrefixMapping = {
      ['Checklist']: <Checkmark className={styles.checkmark} />,
      ['Numbered List']: i + 1,
    };

    return itemPrefixMapping[type] || <Fragment>&ndash;</Fragment>;
  };

  const ListEl = type === 'Numbered List' ? 'ol' : 'ul';

  return (
    <div>
      <ListEl className={styles.list}>
        {isNonEmptyArray(listItems) &&
          listItems.map((item, i) => (
            <li key={i} className={styles.item}>
              <span className={styles.prefix}>{getItemPrefix(type, i)}</span>
              <span className={styles.text}>{parseShortText(item)}</span>
            </li>
          ))}
      </ListEl>
    </div>
  );
};

BigList.propTypes = {
  type: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BigList;
