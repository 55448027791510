import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({ paddingValue }) => (
  <div
    style={{
      padding: `${paddingValue}em`,
    }}
  />
);

Spacer.propTypes = {
  name: PropTypes.string,
  paddingValue: PropTypes.number,
};

export default Spacer;
