import PhoneFormatter from 'phone-formatter';
import { PHONE_NUMBER_LENGTH, PHONE_NUMBER_FORMAT, GOOGLE_MAPS_SEARCH_URL } from './constants';
import { renderHtmlToReact } from '../components/CustomRichText';

const { isInteger } = Number;

export const isString = value => typeof value === 'string';

export const getFirstName = name => {
  try {
    const names = name.split(' ');
    return names[0];
  } catch {
    return null;
  }
};

export const getSlugFromAuthorName = name => {
  try {
    const comma = ',';
    const period = '.';
    const apostrophe = '\'';
    const truncName = name.indexOf(comma) !== -1 ? name.slice(0, name.indexOf(comma)) : name;
    const periodScrubbed = truncName.replace(period, '');
    const apostropheScrubbed = periodScrubbed.replace(apostrophe, '');
    const slug = apostropheScrubbed.replace(/ /g, '-');
    return slug.toLowerCase();
  } catch {
    return null;
  }
};

export const getPath = (slug, parentSlug) => {
  if (slug) {
    return parentSlug ? `/${parentSlug}/${slug}` : `/${slug}`;
  } else if (parentSlug) {
    return `/${parentSlug}`;
  } else {
    return '/';
  }
};

export const formatPhoneNumber = number => {
  if (!isInteger(number)) {
    return null;
  }

  const numberToString = number.toString();

  if (numberToString.length !== PHONE_NUMBER_LENGTH) {
    return null;
  }

  return PhoneFormatter.format(numberToString, PHONE_NUMBER_FORMAT);
};

export const createGoogleMapsLink = address => {
  const urlAddress = encodeURI(address);
  return `${GOOGLE_MAPS_SEARCH_URL}&query=${urlAddress}`;
};

export const createBreadcrumbLinks = location => {
  const parsedPath =
    location.location.pathname[0] === '/'
      ? location.location.pathname.slice(1)
      : location.location.pathname;
  const links = parsedPath.split('/');
  const crumbLinks = [];
  for (let i = 0; i < links.length; i++) {
    // title case crumb
    const crumbLinkWords = links[i].split('-');
    for (let j = 0; j < crumbLinkWords.length; j++) {
      crumbLinkWords[j] = crumbLinkWords[j].charAt(0).toUpperCase() + crumbLinkWords[j].slice(1);
    }
    crumbLinks.push({
      label: links[i].toLowerCase() === 'insights' ? 'Our Insights' : crumbLinkWords.join(' '),
      location: i - 1 >= 0 ? `/${links[i - 1]}/${links[i]}` : `/${links[i]}`,
    });
  }
  return crumbLinks;
};

export const parseShortText = str => {
  if (typeof str !== 'string') {
    return str;
  }

  const replaceChars = [['®', '<sup class="trademark">®</sup>']];

  let parsedStr = str;

  replaceChars.forEach(char => {
    if (str.indexOf(char[0]) === -1) {
      return false;
    } else {
      const replaceAll = new RegExp(char[0], 'g');
      return (parsedStr = parsedStr.replace(replaceAll, char[1]));
    }
  });

  let result = parsedStr;

  if (str !== parsedStr) {
    result = renderHtmlToReact(parsedStr);
  }

  return result;
};

export const showTrailingPeriod = str => {
  const trimmedStr = str.trim();
  if (trimmedStr.endsWith('.') || trimmedStr.endsWith('!') || trimmedStr.endsWith('?')) {
    return '';
  } else {
    return '.';
  }
};

export const toVariableName = str =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '')
    .replace(/-/g, '')
    .replace(' ', '');
