import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import { useForceUpdate } from 'hooks';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './carousel.module.scss';
import Navigation from './Navigation/index';

const Carousel = ({
  children,
  className: customClassName,
  customOptions,
  title,
  description,
  topRightButton,
  name,
  isVideoCarousel,
}) => {
  const VAR_BP_SM = parseInt(styles.varBpSm);
  const VAR_BP_MD_SM = parseInt(styles.varBpMdSm);
  const VAR_BP_MD_LG = parseInt(styles.varBpMdLg);

  // if set count more than available - slides will be duplicated
  const getCorrectCountOfSlidesToShow = number =>
    children?.length > number ? number : children?.length;

  const defaultOptions = isVideoCarousel
    ? {
      adaptiveHeight: false,
      arrows: false,
      draggable: true,
      easing: 'in-out',
      slidesToScroll: 1,
      slidesToShow: getCorrectCountOfSlidesToShow(2),
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: VAR_BP_MD_LG,
          settings: {
            slidesToShow: getCorrectCountOfSlidesToShow(2),
          },
        },
        {
          breakpoint: VAR_BP_MD_SM,
          settings: {
            slidesToShow: getCorrectCountOfSlidesToShow(1),
          },
        },
        {
          breakpoint: VAR_BP_SM,
          settings: {
            centerMode: true,
            slidesToShow: getCorrectCountOfSlidesToShow(1),
          },
        },
      ],
    }
    : {
      adaptiveHeight: false,
      arrows: false,
      draggable: true,
      easing: 'in-out',
      slidesToScroll: 1,
      slidesToShow: getCorrectCountOfSlidesToShow(4),
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: VAR_BP_MD_LG,
          settings: {
            slidesToShow: getCorrectCountOfSlidesToShow(3),
          },
        },
        {
          breakpoint: VAR_BP_MD_SM,
          settings: {
            slidesToShow: getCorrectCountOfSlidesToShow(2),
          },
        },
        {
          breakpoint: VAR_BP_SM,
          settings: {
            centerMode: true,
            slidesToShow: getCorrectCountOfSlidesToShow(1),
          },
        },
      ],
    };

  const options = {
    ...defaultOptions,
    ...customOptions,
  };

  const carouselRef = useRef();
  const sliderRef = useRef();
  const nextSlide = useRef(() => {});
  const prevSlide = useRef(() => {});

  const update = useForceUpdate();

  const [dragging, setDragging] = useState(false);
  const [slidesCountOnPage, setSlidesCount] = useState(defaultOptions.slidesToShow);

  const unfocusHiddenAnchorTags = () => {
    if (carouselRef.current) {
      Array.from(
        carouselRef.current
          .querySelector('.slick-slider')
          .querySelectorAll('div[aria-hidden="true"] a'),
      ).forEach(a => (a.tabIndex = -1));
    }
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
    unfocusHiddenAnchorTags();
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    e => {
      if (dragging) {
        e.preventDefault();
      }
    },
    [dragging],
  );

  const updateSlidesCountOnPage = () => {
    setSlidesCount(
      defaultOptions.responsive.find(s => s.breakpoint === sliderRef.current.state.breakpoint)
        ?.settings?.slidesToShow || defaultOptions.slidesToShow,
    );
  };

  useEffect(() => {
    if (sliderRef.current) {
      nextSlide.current = sliderRef.current.slickNext;
      prevSlide.current = sliderRef.current.slickPrev;
      updateSlidesCountOnPage();
      unfocusHiddenAnchorTags();
      update();
    }
  }, [sliderRef.current]);

  useEffect(() => {
    function handleResize() {
      updateSlidesCountOnPage();
      update();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Used to point the aria-labelledby attribute of the carousel's <nav />
  // to this carousel's <h1 />.
  // An accessibility precaution in case a page has more than one carousel.
  // If there's no title, use the name field from Contentful
  let carouselTitleId;
  let carouselIdRaw;
  title ? (carouselIdRaw = title) : (carouselIdRaw = name);

  carouselIdRaw &&
    (carouselTitleId = `carousel-${carouselIdRaw
      .replace(/[^\w\s]|_/g, '_') // replace all punctuation with underscores
      .split(' ') // convert to array of words
      .slice(0, 3) // get the first 3 words
      .join('-')}`); // join into a kebab case id

  const carouselChildrenCounter = isVideoCarousel ? 2 : 3;

  const slidesMoreThanShowing =
    slidesCountOnPage < children.length && children.length > carouselChildrenCounter;

  return (
    <section {...className(customClassName, styles.outerWrapper)} ref={carouselRef}>
      <header {...className(styles.header, title && styles.headerPadding, 'page-left-inset')}>
        <h1
          id={carouselTitleId}
          {...className(styles.heading, topRightButton && styles.small, !title && styles.hide)}>
          {title}
        </h1>

        {topRightButton ? (
          <div {...className(styles.buttonWrapper, styles.desktopOnly)}>{topRightButton}</div>
        ) : (
          carouselTitleId &&
          slidesMoreThanShowing && (
            <Navigation
              prev={prevSlide.current}
              next={nextSlide.current}
              carouselTitleId={carouselTitleId}
            />
          )
        )}
        {description && <p className={styles.description}>{description}</p>}
      </header>
      <Slider
        className={styles.slider}
        {...options}
        ref={sliderRef}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}>
        {React.Children.map(children, child => (
          <div onClickCapture={handleOnItemClick}>{child}</div>
        ))}
      </Slider>
      {topRightButton && slidesMoreThanShowing && (
        <div {...className(styles.footer, 'page-left-inset')}>
          <div {...className(styles.buttonWrapper, styles.mobileOnly)}>{topRightButton}</div>
          <Navigation
            prev={prevSlide.current}
            next={nextSlide.current}
            carouselTitleId={carouselTitleId}
          />
        </div>
      )}
    </section>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  customOptions: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  topRightButton: PropTypes.node,
  name: PropTypes.string,
  isVideoCarousel: PropTypes.bool,
};

export default Carousel;
