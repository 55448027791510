import React from 'react';
import PropTypes from 'prop-types';
import { renderHtmlToReact } from '../CustomRichText';
import styles from './ceros.module.scss';
import { className } from 'utils';

const Ceros = ({ html }) => {
  if (!html) {
    return null;
  }
  return (
    <div {...className('component', styles.cerosContainer)}>
      {renderHtmlToReact(html)}
    </div>);
};

Ceros.propTypes = {
  html: PropTypes.string.isRequired,
};

export default Ceros;
