import React, { useMemo, useRef, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { FacebookLogo, LinkedInLogo, TwitterLogo } from 'svgs';
import { Link, SocialLink } from 'components';
import {
  className,
  safeGet,
  BASE_PATHS,
  getSlugFromAuthorName,
  getQueryParams,
  isNonEmptyArray,
} from 'utils';
import { authorPropTypes, topicPropTypes } from 'utils/proptypes';
import styles from './articleInfo.module.scss';
import { EmailWhiteLogo } from 'svgs/Email';

const ArticleInfo = ({ className: customClassName, topics, author, date, articleTitle }) => {
  const { global } = useStaticQuery(
    graphql`
      query UrlQuery {
        global: contentfulGlobal {
          url
        }
      }
    `,
  );

  const { name, title, description, image, linkText, linkUrl } = safeGet(
    author,
    'name',
    'title',
    'image',
    ['description', 'description'],
    'linkText',
    'linkUrl',
  );
  const { url: authorImageUrl, description: authorImageDescription } = safeGet(
    image,
    ['file', 'url'],
    ['description'],
  );
  const pathname = useRef(null);
  const url = useMemo(() => `${global.url}${pathname.current}`, [pathname.current, global.url]);

  useEffect(() => {
    const formattedDate = date.split('.').join('/');
    const publishDate = new Date(formattedDate).toISOString().slice(0, 10);
    pathname.current = location.pathname;
    window.dataLayer.push({
      author: name || null,
      publishDate: publishDate || null,
    });
  }, [date, name]);

  return (
    <div {...className(customClassName, 'page-left-inset', 'page-left-inset-content')}>
      <section className={styles.articleInfo}>
        <div className={styles.inner}>
          <div className={styles.infoSection}>
            <div className={styles.author}>
              <div className={styles.authorName}>
                {name &&
                  (getSlugFromAuthorName(name) ? (
                    <Link
                      className={styles.link}
                      to={`/${BASE_PATHS.author}/${getSlugFromAuthorName(name)}`}>
                      {name}
                    </Link>
                  ) : (
                    <h1>{name}</h1>
                  ))}
                {title && <h2>{title}</h2>}
              </div>
              {authorImageUrl && (
                <div className={styles.portraitMask}>
                  <img
                    src={`${authorImageUrl}${getQueryParams({ w: 74, h: 74, fit: 'fill' })}`}
                    alt={authorImageDescription || name}
                  />
                </div>
              )}
            </div>
            {description && <p className={styles.authorBio}>{description}</p>}
            {linkText && linkUrl && (
              <Link to={linkUrl} className={styles.link}>
                {linkText}
              </Link>
            )}
          </div>
          {isNonEmptyArray(topics) && (
            <div className={styles.infoSection}>
              <h2 className={styles.head}>Topics</h2>
              <div className={styles.topics}>
                {topics.map(({ name, slug }, i) =>
                  name && slug ? (
                    <span key={i}>
                      {i ? ', ' : ''}
                      <Link to={`/${BASE_PATHS.insights}/${slug}`} className={styles.link}>
                        {name}
                      </Link>
                    </span>
                  ) : null,
                )}
              </div>
            </div>
          )}
          {date && (
            <div className={styles.infoSection}>
              <h2 className={styles.head}>Published</h2>
              <p>{date}</p>
            </div>
          )}
          <div className={styles.infoSection}>
            <h2 className={styles.head}>Share</h2>
            <ul>
              <SocialLink
                text="Email"
                to={`mailto:?subject=${articleTitle}&body=${url}`}
                logoSvg={EmailWhiteLogo}
              />
              <SocialLink
                text="Share to LinkedIn"
                to={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${articleTitle}`}
                logoSvg={LinkedInLogo}
              />
              <SocialLink
                text="Share to Twitter"
                to={`http://twitter.com/share?text=${articleTitle}&url=${url}`}
                logoSvg={TwitterLogo}
              />
              <SocialLink
                text="Share to Facebook"
                to={`http://www.facebook.com/sharer.php?u=${url}&p[title]=${articleTitle}`}
                logoSvg={FacebookLogo}
              />
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

ArticleInfo.propTypes = {
  className: PropTypes.string,
  topics: PropTypes.arrayOf(topicPropTypes),
  author: authorPropTypes,
  date: PropTypes.string.isRequired,
  articleTitle: PropTypes.string.isRequired,
};

export default ArticleInfo;
