import readingTime from 'reading-time';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const getRichTextReadingTime = content => {
  if (!content?.json) {
    return null;
  }
  const contentText = documentToPlainTextString(content.json);
  return readingTime(contentText);
};

export default getRichTextReadingTime;
