import React from 'react';
import { className, toVariableName } from 'utils';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './linkText.module.scss';

const LinkText = ({ buttonLink, buttonText, className: customClassName }) => {
  const automationId = `PS_LinkText_${toVariableName(buttonText)}`;
  return (
    <div {...className(styles.buttonWrapper, customClassName, 'button-link')}>
      <Link
        data-automation-id={toVariableName(automationId)}
        className={styles.button}
        to={buttonLink}
        newTab>
        {buttonText}
      </Link>
    </div>
  );
};

LinkText.propTypes = {
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
};

export default LinkText;
