import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import { articleStubPropTypes, topicPropTypes } from 'utils/proptypes';
import { ArticleGrid, ContactForm, Paginate, TopicList, TopicsCarousel } from 'components';
import { HSFORM_EMAIL_SUBSCRIPTION } from 'utils/constants';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import styles from './articleIndex.module.scss';

const ArticleIndex = ({
  articles,
  basePath,
  className: customClassName,
  currentPage,
  numPages,
  introHeadline,
  introText,
  topics,
  featuredTopics,
}) => {
  const withCarousel = featuredTopics && currentPage === 1;
  const numberOfPreCarouselArticles = 6;
  const preCarouselArticles = articles.slice(0, numberOfPreCarouselArticles);
  const postCarouselArticles = articles.slice(numberOfPreCarouselArticles - 2, articles.length);

  const { transitionStatus } = useTransitionState();
  return (
    <Fragment>
      {withCarousel ? (
        <Fragment>
          {/* In order to let the animated page background show through the carousel, we
          split the page up into multiple top level containers separated by the carousel. */}
          <section {...className(customClassName, styles.outerWrapper, 'page-left-inset')}>
            <div className={styles.innerWrapper}>
              <ArticleGrid
                articles={preCarouselArticles}
                withIntro
                introHeadline={introHeadline}
                introText={introText}
                {...className(
                  styles.articleList,
                  styles.articleGrid,
                  styles.preCarouselArticles,
                  'no-pagination',
                )}
              />
              <aside {...className(styles.rightGutter, styles.preCarouselArticlesGutter)}>
                <TopicList
                  topics={topics}
                  {...className(styles.articleIndexTopicList, styles.desktopOnly)}
                />
                {transitionStatus === 'entered' && (
                  <ContactForm
                    title={HSFORM_EMAIL_SUBSCRIPTION.TITLE}
                    hubspotFormId={HSFORM_EMAIL_SUBSCRIPTION.FORMID}
                    displayTitle={true}
                    format={'Float Right'}
                    uid={HSFORM_EMAIL_SUBSCRIPTION.FORMID}
                    customClassName={styles.articleIndexSubscription}
                    showLoading={transitionStatus !== 'entered'}
                  />
                )}
              </aside>
            </div>
          </section>
          <TopicsCarousel topics={featuredTopics} />
          <section {...className(customClassName, styles.outerWrapper, 'page-left-inset')}>
            <div className={styles.innerWrapper}>
              {numPages > 1 && (
                <Paginate
                  currentPage={currentPage}
                  numPages={numPages}
                  basePath={basePath}
                  {...className(styles.pagination, styles.paginationTop)}
                />
              )}
              <ArticleGrid
                articles={postCarouselArticles}
                {...className(
                  styles.articleList,
                  styles.articleGrid,
                  styles.postCarouselArticles,
                  numPages < 2 && 'no-pagination',
                )}
              />
              {numPages > 1 && (
                <Paginate
                  currentPage={currentPage}
                  numPages={numPages}
                  basePath={basePath}
                  {...className(styles.pagination, styles.paginationBottom)}
                />
              )}
              <aside className={styles.rightGutter}>
                <TopicList
                  topics={topics}
                  {...className(
                    styles.topicList,
                    styles.mobileOnly,
                    styles.postCarouselArticleIndexTopicList,
                  )}
                />
              </aside>
            </div>
          </section>
        </Fragment>
      ) : (
        <section {...className(customClassName, styles.outerWrapper, 'page-left-inset')}>
          <div className={styles.innerWrapper}>
            {numPages > 1 && (
              <Paginate
                currentPage={currentPage}
                numPages={numPages}
                basePath={basePath}
                {...className(styles.pagination, styles.paginationTop, styles.paginationCapWidth)}
              />
            )}
            <ArticleGrid
              articles={articles}
              {...className(
                styles.articleList,
                styles.articleGrid,
                numPages < 2 && 'no-pagination',
              )}
            />
            {numPages > 1 && (
              <Paginate
                currentPage={currentPage}
                numPages={numPages}
                basePath={basePath}
                {...className(styles.pagination, styles.paginationBottom)}
              />
            )}
            <aside className={styles.rightGutter}>
              <TopicList
                topics={topics}
                {...className(styles.articleIndexTopicList, styles.desktopOnly)}
              />
              {transitionStatus === 'entered' && (
                <ContactForm
                  title={HSFORM_EMAIL_SUBSCRIPTION.TITLE}
                  hubspotFormId={HSFORM_EMAIL_SUBSCRIPTION.FORMID}
                  displayTitle={true}
                  format={'Float Right'}
                  uid={HSFORM_EMAIL_SUBSCRIPTION.FORMID}
                  customClassName={styles.articleIndexSubscription}
                  showLoading={transitionStatus !== 'entered'}
                />
              )}
            </aside>
          </div>
        </section>
      )}
    </Fragment>
  );
};

ArticleIndex.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      node: articleStubPropTypes,
    }),
  ),
  basePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  featuredTopics: PropTypes.arrayOf(topicPropTypes),
  introHeadline: PropTypes.string,
  introText: PropTypes.object,
  numPages: PropTypes.number.isRequired,
  topics: PropTypes.arrayOf(topicPropTypes),
};

export default ArticleIndex;
