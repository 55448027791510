import React from 'react';
import PropTypes from 'prop-types';

import Inline from './Inline';
import FullWidth from './FullWidth';
import Sidebar from './Sidebar';
import TextWidth from './TextWidth';

const PullQuote = ({ type, ...props }) => {
  const mapping = {
    ['Full Width']: FullWidth,
    ['Inline']: Inline,
    ['Sidebar']: Sidebar,
    ['Text Width']: TextWidth,
  };

  const _component = mapping[type] || Inline;

  return <_component {...props} />;
};

PullQuote.propTypes = {
  type: PropTypes.string,
};

export default PullQuote;
