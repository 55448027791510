import React, { useContext, useState, useEffect, forwardRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { useLinks, useBigList } from 'hooks';
import { Wordmark } from 'svgs';
import { Link } from 'components';
import DesktopNav from './Desktop';
import MobileNav from './Mobile';
import styles from './navigation.module.scss';
import { className } from 'utils';
import { BlankTemplateContext } from 'utils/constants';

const VAR_BP_NAV = parseInt(styles.varBpNav);

export const useMenuItems = ({ list, mainDropdownNavigation, menuFormatType, ...rest }) => {
  const [bigsLists] = useState(useBigList(mainDropdownNavigation || {}));
  const [links] = useState(useLinks(list || {}));
  const menuLinks = bigsLists.map(i => ({
    to: i.link,
    text: [i.heading],
    description: i.body,
  }));

  return {
    ...rest,
    menuLinks,
    links,
    menuFormatType: menuFormatType?.length ? menuFormatType[0] : null,
  };
};

const Navigation = forwardRef(({ isMobileMenuOpen, siteTitle }, ref) => {
  const {
    global: { navLinks, menu, buttonText, buttonLink },
  } = useStaticQuery(graphql`
    query NavQuery {
      global: contentfulGlobal {
        navLinks {
          json
        }
        menu {
          heading
          headingUrl
          dropdownHeading
          menuFormatType
          list {
            json
          }
          mainDropdownNavigation {
            json
          }
          cta1NavigationImage {
            file {
              url
            }
            title
          }
          cta1NavigationIntro
          cta1NavigationLink
          cta1NavigationButtonText
          cta2NavigationIntro
          cta2NavigationLink
          cta2NavigationButtonText
        }
        buttonText
        buttonLink
      }
    }
  `);

  const [isMobile, setIsMobile] = useState(true);
  const { isBlankTemplate } = useContext(BlankTemplateContext);

  const handleResize = () => {
    setIsMobile(window.innerWidth < VAR_BP_NAV);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navItemsWithDropdowns = menu.map(useMenuItems);
  const utilityLinks = useLinks(navLinks);

  return (
    <header
      {...className(
        styles.header,
        !isMobileMenuOpen && styles.headerMobileMenuClosed,
        isMobileMenuOpen && styles.headerMobileMenuOpen,
      )}
      ref={ref}>
      <div className={styles.logoContainer}>
        <Link
          id="logo"
          {...className(styles.wordmarkWrapper, isMobileMenuOpen && styles.linkNotClickable)}
          to="/"
          title={siteTitle}>
          <Wordmark {...className(styles.wordmark)} />
          <span className={styles.visuallyHidden}>Commonwealth Logo</span>
        </Link>
      </div>
      {!isBlankTemplate && (
        <>
          {isMobile && (
            <MobileNav
              navItemsWithDropdowns={navItemsWithDropdowns}
              utilityLinks={utilityLinks}
              menu={menu}
              isMobileMenuOpen={isMobileMenuOpen}
              buttonLink={buttonLink}
              buttonText={buttonText}
            />
          )}
          <DesktopNav
            style={isMobile ? { display: 'none' } : {}}
            navItemsWithDropdowns={navItemsWithDropdowns}
            utilityLinks={utilityLinks}
            menu={menu}
            isMobileMenuOpen={isMobileMenuOpen}
            buttonLink={buttonLink}
            buttonText={buttonText}
          />
        </>
      )}
    </header>
  );
});

Navigation.propTypes = {
  siteTitle: PropTypes.string,
  isMobileMenuOpen: PropTypes.bool.isRequired,
};

Navigation.displayName = 'Navigation';

export default Navigation;
