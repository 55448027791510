import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { className, renderText } from 'utils';
import { menuPropTypes } from 'utils/proptypes';

import styles from './navigation.module.scss';

const Navigation = ({ className: customClassName, menu }) => {
  const navigationContainer = useRef();

  const addActiveContainerClass = domEl => {
    const ulTags = navigationContainer.current.querySelectorAll('ul');

    Array.from(ulTags)
      .filter(ul => ul.contains(domEl))
      .forEach(ul => ul.classList.add(styles.activeList));
  };

  useEffect(() => {
    const currentLinks = navigationContainer.current.getElementsByClassName(
      'navigation-current-page',
    );

    if (currentLinks.length) {
      Array.from(currentLinks).forEach(currentLink => {
        const liTag = currentLink.parentElement.parentElement;
        liTag.classList.add(styles.currentTitle);
        addActiveContainerClass(currentLink);
      });
    }
  }, []);

  return (
    <nav {...className(styles.pagesNavigation, customClassName)} aria-label="Related Pages">
      {menu.heading && (
        <h2 className={styles.pagesNavigationOverline}>
          {menu.headingUrl ? <Link to={menu.headingUrl}>{menu.heading}</Link> : menu.heading}
        </h2>
      )}
      <div className={styles.navigationContainer} ref={navigationContainer}>
        {renderText(menu.list)}
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  menu: menuPropTypes.isRequired,
};

export default Navigation;
