import React from 'react';
import PropTypes from 'prop-types';
import { safeGet, className, getQueryParams, isNonEmptyArray } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import { Link } from 'components';

import styles from './largeImage.module.scss';
import ctaBaseStyles from '../ctaBase.module.scss';

const LargeImage = ({ headline, buttons, image, type }) => {
  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    image,
    'title',
    'description',
    ['file', 'url'],
  );

  const relatedLinks = type.includes('Related Links');

  return (
    <section
      {...className(
        'component page-left-inset',
        ctaBaseStyles.cta,
        ctaBaseStyles.withBackground,
        styles.largeImage,
      )}>
      <div className={ctaBaseStyles.row}>
        <div className={styles.copy}>
          {imageUrl && (
            <figure className={styles.imageWrapper}>
              <div className={styles.aspectRatioWrapper}>
                <img
                  className={styles.image}
                  src={`${imageUrl}${getQueryParams({ w: 600, h: 900, fit: 'fill' })}`}
                  alt={imageDescription || imageTitle}
                />
              </div>
            </figure>
          )}
          {headline && <h1 className={styles.headline}>{headline}</h1>}
        </div>
        {buttons && isNonEmptyArray(buttons) && (
          <div className={styles.buttonWrapper}>
            {buttons.map((item, i) => (
              <Link
                {...className(styles.button, relatedLinks && styles.buttonRelated)}
                to={item[1]}
                key={i}
                newTab>
                {item[0]}
              </Link>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

LargeImage.propTypes = {
  headline: PropTypes.string,
  buttons: PropTypes.array,
  image: imagePropTypes,
  type: PropTypes.string,
};

export default LargeImage;
