import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import LargeImage from './LargeImage';
import FullWidth from './FullWidth';
import Subscribe from './Subscribe';
import Footer from './Footer';
import RecircList from '../RecircList';
import TextSidebar from './TextSidebar';
import TextWidth from './TextWidth';
import SlideShare from './SlideShare';

import { CTA_TYPES } from '../../utils/constants';

const CTA = props => {
  const { onFooter, showWhiteArea } = props;

  const { global } = useStaticQuery(graphql`
    fragment CTA on ContentfulCta {
      ctaType
      content {
        json
      }
      headline
      buttonText
      buttonLink
      buttons {
        content
      }
      hubspotFormId
      image {
        title
        file {
          url
        }
      }
      signature {
        title
        file {
          url
        }
      }
      sidebarBlockquote {
        quoteName
        showQuotes
        attribution
        attributionSecondLine
        type
        attributionThumbnail {
          title
          file {
            url
          }
        }
        image {
          title
          file {
            url
          }
        }
      }
    }

    query GlobalCTAQuery {
      global: contentfulGlobal {
        footerCta {
          ...CTA
        }
      }
    }
  `);

  const isFooterOnlyCta = ({ ctaType }) => ctaType === CTA_TYPES.FOOTER_ONLY;
  const isNotFooterOnlyCta = cta => !isFooterOnlyCta(cta);
  const isSidebarCta = ({ ctaType }) =>
    ctaType === CTA_TYPES.RELATED_LINKS_SIDEBAR || ctaType === CTA_TYPES.TEXT_SIDEBAR;

  const ctaPropsOptions = [props, global.footerCta];

  const { ctaType, ...rest } =
    ctaPropsOptions.find(onFooter ? isFooterOnlyCta : isNotFooterOnlyCta) || {};

  const mapping = {
    [CTA_TYPES.LARGE_IMAGE]: LargeImage,
    [CTA_TYPES.SMALL_CIRCULAR_IMAGE]: FullWidth,
    [CTA_TYPES.SUBSCRIBE]: Subscribe,
    [CTA_TYPES.RELATED_LINKS_SIDEBAR]: RecircList,
    [CTA_TYPES.RELATED_LINKS_FULL_WIDTH]: FullWidth,
    [CTA_TYPES.FOOTER_ONLY]: Footer,
    [CTA_TYPES.TEXT_SIDEBAR]: TextSidebar,
    [CTA_TYPES.TEXT_WIDTH]: TextWidth,
    [CTA_TYPES.SLIDESHARE]: SlideShare,
  };

  const ctaProps = { ...rest, showWhiteArea: showWhiteArea };

  const _component = mapping[ctaType];
  return _component ? (
    <_component {...ctaProps} type={ctaType} floatRight={isSidebarCta && true} />
  ) : null;
};

CTA.propTypes = {
  props: PropTypes.object,
  onFooter: PropTypes.bool,
  showWhiteArea: PropTypes.bool,
};

CTA.defaultProps = {
  onFooter: false,
};

export default CTA;
