import React from 'react';
import PropTypes from 'prop-types';
import styles from './outlinedText.module.scss';

const OutlinedText = ({ content }) => <span className={styles.content}>{content}</span>;

OutlinedText.propTypes = {
  content: PropTypes.string,
};

export default OutlinedText;
