import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ className: customClassName }) => (
  <svg className={customClassName} viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
    <circle cx="26" cy="26" r="26" />
    <path d="M14 14L38 38M38 14L14 38" stroke="#293A31" />
  </svg>
);

Close.propTypes = {
  className: PropTypes.string,
};

export default Close;
