import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { useHubspotForm } from 'hooks';
import { className, renderText } from 'utils';
import styles from './contactForm.module.scss';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';

const ContactForm = ({
  title,
  contactIntro,
  hubspotFormId,
  displayTitle,
  format,
  uid,
  customClassName,
  showLoading,
  pardotFormLink,
  // pardotFormWidth,
  pardotFormHeight,
}) => {
  const contactFormId = `hubspot-form-${uid}`;
  const { transitionStatus } = useTransitionState();
  useHubspotForm(hubspotFormId, `#${contactFormId}`, transitionStatus);

  const isFullWidth = format === 'Full Width';
  const isFloatRight = format === 'Float Right';
  const isContact = format === 'Contact';

  const [formWidth, setFormWidth] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => {
      const wrapperWidth = document.getElementById('iframe-wrapper')?.offsetWidth;
      setFormWidth(wrapperWidth);
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <section
      {...className(
        styles.formSection,
        isFullWidth && styles.fullWidth,
        isFloatRight && `${styles.floatRight} component`,
        isContact && styles.contact,
        customClassName,
      )}>
      {displayTitle && <h2 className={styles.title}>{title}</h2>}
      {contactIntro && <div className={styles.intro}>{renderText(contactIntro)}</div>}
      {pardotFormLink ? (
        <div style={{ width: '100%', overflow: 'auto' }} id="iframe-wrapper">
          <iframe
            title="contact-form"
            src={pardotFormLink}
            width={formWidth}
            height={pardotFormHeight}
          />
        </div>
      ) : (
        <>
          {showLoading ? (
            <Loading type="Component" />
          ) : (
            <div id={contactFormId} className={styles.formWrapper}></div>
          )}
        </>
      )}
    </section>
  );
};

ContactForm.propTypes = {
  title: PropTypes.string.isRequired,
  contactIntro: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  hubspotFormId: PropTypes.string.isRequired,
  displayTitle: PropTypes.bool,
  format: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
  showLoading: PropTypes.bool.isRequired,
  pardotFormLink: PropTypes.string,
  pardotFormHeight: PropTypes.string,
  pardotFormWidth: PropTypes.string,
};

export default ContactForm;
