import React from 'react';
import PropTypes from 'prop-types';
import { className, safeGet } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import { authorPortraitParams } from 'utils/imageQueryParams';
import Link from '../../Link';
import styles from './fullWidthBlockquote.module.scss';

const FullWidthBlockquote = ({
  content,
  withQuotes = true,
  attribution,
  attributionSecondLine,
  attributionLink,
  attributionThumbnail,
}) => {
  const { url: authorImageUrl, description: authorImageDescription } = safeGet(
    attributionThumbnail,
    'description',
    ['file', 'url'],
  );

  return (
    <figure {...className('component', styles.wrapper)}>
      <blockquote {...className(styles.quoteBody, withQuotes && styles.withQuotes)}>
        {content}
      </blockquote>
      {attribution && (
        <figcaption className={styles.attribution}>
          {authorImageUrl && (
            <div className={styles.portraitWrapper}>
              <img
                // must be cropped to square
                src={`${authorImageUrl}${authorPortraitParams}`}
                alt={authorImageDescription || attribution}
              />
            </div>
          )}
          <div>
            <cite className={styles.author}>{attribution}</cite>
            {attributionLink ? (
              <Link to={attributionLink}>{attributionSecondLine}</Link>
            ) : (
              attributionSecondLine
            )}
          </div>
        </figcaption>
      )}
    </figure>
  );
};

FullWidthBlockquote.propTypes = {
  attribution: PropTypes.string,
  attributionSecondLine: PropTypes.string,
  attributionLink: PropTypes.string,
  attributionThumbnail: imagePropTypes,
  content: PropTypes.string.isRequired,
  withQuotes: PropTypes.bool,
};

export default FullWidthBlockquote;
