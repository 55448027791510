import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { className } from 'utils';
import styles from './loadingScreen.module.scss';

const LoadingScreen = forwardRef((props, ref) => {
  const loadingScreen = useRef();
  const stripeB = useRef();
  const stripeF = useRef();
  //animation lives in layout.js

  useImperativeHandle(ref, () => ({
    loadingScreen: loadingScreen.current,
    stripeB: stripeB.current,
    stripeF: stripeF.current,
  }));

  return (
    <div id="loading_screen" className={styles.loadingScreen} ref={loadingScreen}>
      <div {...className(styles.stripe, styles.stripeB)} ref={stripeB}></div>
      <div {...className(styles.stripe, styles.stripeF)} ref={stripeF}></div>
    </div>
  );
});

LoadingScreen.displayName = 'Loading';

export default LoadingScreen;
