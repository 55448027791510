import React from 'react';
import PropTypes from 'prop-types';
import { connectSearchBox } from 'react-instantsearch-dom';
import { className } from 'utils';
import { SearchIcon } from 'svgs';
import styles from './searchTopper.module.scss';

const SearchBox = ({ currentRefinement, refine }) => {
  const clearQuery = () => refine('');

  return (
    <label htmlFor="pageSearch" className={styles.searchInputWrapper}>
      <span className={styles.hiddenLabelText}>Search</span>
      <input
        id="pageSearch"
        type="text"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        placeholder="Find what you're looking for"
        className={styles.searchInput}
        aria-label="Search Commonwealth Financial"
      />
      {currentRefinement ? (
        <button className={styles.clearButton} onClick={clearQuery}>
          &times;
        </button>
      ) : (
        <SearchIcon className={styles.searchIcon} />
      )}
    </label>
  );
};

SearchBox.propTypes = {
  currentRefinement: PropTypes.string,
  isSearchStalled: PropTypes.bool,
  refine: PropTypes.func,
};

const CustomSearchBox = connectSearchBox(SearchBox);

const SearchTopper = () => (
  <header {...className('page-left-inset', 'page-left-inset-topper')}>
    <div className={styles.innerWrapper}>
      <div className={styles.row}>
        <CustomSearchBox />
      </div>
    </div>
  </header>
);

SearchTopper.propTypes = {
  query: PropTypes.string,
  results: PropTypes.array,
};

export default SearchTopper;
