import React, { useEffect, useRef } from 'react';
import { className } from 'utils';
import { TweenMax, Power3 } from 'gsap';
import styles from './loadingComponent.module.scss';

const LoadingComponent = () => {
  const loadingComponent = useRef();
  const stripeB = useRef();
  const stripeF = useRef();

  const initializeAnimation = () => {
    TweenMax.set(loadingComponent.current, { force3D: true });
    TweenMax.to([stripeB.current, stripeF.current], 0.2, {
      autoAlpha: 1,
    });

    TweenMax.to(stripeF.current, 0.4, {
      scaleX: 0.8,
      onComplete: () => {
        TweenMax.to(stripeF.current, 0.2, {
          scaleX: 1,
          delay: 0.4,
          onComplete: () => {
            TweenMax.to(stripeF.current, 0.5, {
              scaleX: 0,
              delay: 0.1,
              ease: Power3.easeInOut,
              transformOrigin: '100% 0%',
            });
            TweenMax.to(stripeB.current, 0.5, {
              scaleX: 0,
              delay: 0.1,
              ease: Power3.easeInOut,
              transformOrigin: '100% 0%',
            });
          },
        });
      },
    });
  };

  useEffect(() => {
    initializeAnimation();
  });

  return (
    <div id="loading_component" className={styles.loadingComponent} ref={loadingComponent}>
      <div {...className(styles.stripe, styles.stripeB)} ref={stripeB}></div>
      <div {...className(styles.stripe, styles.stripeF)} ref={stripeF}></div>
    </div>
  );
};

LoadingComponent.displayName = 'Loading Component';

export default LoadingComponent;
