import React from 'react';
import PropTypes from 'prop-types';
import { className, isNonEmptyArray } from 'utils';
import { offeringPropTypes } from 'utils/proptypes';
import Offering from './Offering';
import styles from './offerings.module.scss';

const Offerings = ({ offerings }) => (
  <section {...className(styles.outerWrapper, 'page-left-inset')}>
    <ul className={styles.innerWrapper}>
      {isNonEmptyArray(offerings) &&
        offerings.map((offering, i) => (
          <li key={i} className={styles.offeringWrapper}>
            <Offering {...offering} number={i + 1} />
          </li>
        ))}
    </ul>
  </section>
);

Offerings.propTypes = {
  offerings: PropTypes.arrayOf(offeringPropTypes),
};

export default Offerings;
