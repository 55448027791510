import React from 'react';
import PropTypes from 'prop-types';
import { className, parseShortText } from 'utils';
import { Link } from 'components';
import styles from './twoUpList.module.scss';
import WideRightArrow from '../../svgs/Arrow/WideRight';

const TwoUpList = ({ items = [] }) => {
  const itemsMarkup = items.map((item, i) => {
    const head = item.link ? (
      <Link to={item.link} className={styles.headingLink}>
        {parseShortText(item.heading)}
      </Link>
    ) : (
      parseShortText(item.heading)
    );

    return (
      <div key={i} className={styles.item}>
        <h2 className={styles.heading}>{head}</h2>
        <div className={styles.body}>{parseShortText(item.body)}</div>

        {item.link && (
          <Link to={item.link} className={styles.link}>
            <span className={styles.visuallyHidden}>Right Arrow</span>
            <WideRightArrow />
          </Link>
        )}
      </div>
    );
  });

  return <div {...className('component', styles.twoUpList)}>{itemsMarkup}</div>;
};

TwoUpList.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
};

export default TwoUpList;
