import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './hamburger.module.scss';

const Hamburger = ({ className: customClassName }) => (
  <svg
    {...className(styles.hamburger, customClassName)}
    width="40px"
    height="25px"
    viewBox="0 0 40 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="0" width="40" height="3" className={styles.top} />
    <rect x="0" y="11" width="40" height="3" className={styles.middle} />
    <rect x="0" y="22" width="40" height="3" className={styles.bottom} />
  </svg>
);

Hamburger.propTypes = {
  className: PropTypes.string,
};

export default Hamburger;
