import React from 'react';
import { articleStubPropTypes, topicPropTypes } from 'utils/proptypes';
import { BASE_PATHS, safeGet } from 'utils';
import { Link } from 'components';
import stylesBig from './articleTeaseBig.module.scss';
import stylesSmall from './articleTeaseSmall.module.scss';

const TopicLink = ({ topic }) => {
  const { name, slug } = safeGet(topic, 'name', 'slug');

  return name ? (
    <h2 className={stylesBig.topic}>
      <Link to={`/${BASE_PATHS.insights}/${slug}`} className={stylesBig.topicLink}>
        {name}
      </Link>
    </h2>
  ) : null;
};

TopicLink.propTypes = {
  topic: topicPropTypes,
};

const ArticleTeaseBig = ({ article }) => {
  const { topic, title, slug, date, author, articleImage } = safeGet(
    article,
    'title',
    'slug',
    'date',
    'author',
    ['intro', 'intro'],
    {
      query: ['topics', 0],
      name: 'topic',
    },
    'articleImage',
  );

  const { title: topicTitle, description: topicDescription, url: topicImageUrl } = safeGet(
    topic,
    ['image', 'title'],
    ['image', 'description'],
    ['image', 'file', 'url'],
  );

  const { url: authorImageURL, description: authorImageDescription, name: authorName } = safeGet(
    author,
    'name',
    ['image', 'file', 'url'],
    ['image', 'description'],
  );

  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    articleImage,
    ['title'],
    ['description'],
    ['file', 'url'],
  );

  return (
    <div className={stylesBig.tease}>
      {(topicImageUrl || imageUrl) && (
        <Link to={`/${BASE_PATHS.insights}/${slug}`} className={stylesBig.topicImgLink}>
          {imageUrl ? (
            <img
              className={stylesBig.topicImg}
              src={imageUrl}
              alt={imageDescription || imageTitle}
            />
          ) : (
            <img
              className={stylesBig.topicImg}
              src={topicImageUrl}
              alt={topicDescription || topicTitle}
            />
          )}
        </Link>
      )}
      <div className={stylesBig.text}>
        {topic && <TopicLink topic={topic} />}
        <h3 className={stylesBig.title}>
          <Link to={`/${BASE_PATHS.insights}/${slug}`} className={stylesBig.titleLink}>
            {title}
          </Link>
        </h3>
        <div className={stylesBig.authorLockup}>
          {authorImageURL && (
            <img
              className={stylesBig.authorImg}
              src={authorImageURL}
              alt={authorImageDescription || authorName}
            />
          )}
          <div className={stylesBig.authorNameDate}>
            {authorName && <p className={stylesBig.authorName}>{authorName}</p>}
            {date && <p className={stylesBig.publishDate}>{date}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

const ArticleTeaseSmall = ({ article }) => {
  const { title, date, slug, intro } = safeGet(article, 'title', 'date', 'slug', [
    'intro',
    'intro',
  ]);

  return (
    <div className={stylesSmall.tease}>
      {date && <h2 className={stylesSmall.publishDate}>{date}</h2>}
      <h3 className={stylesSmall.title}>
        <Link to={`/${BASE_PATHS.insights}/${slug}`} className={stylesSmall.titleLink}>
          {title}
        </Link>
      </h3>
      {intro && <p className={stylesSmall.intro}>{intro}</p>}
    </div>
  );
};

ArticleTeaseBig.propTypes = {
  article: articleStubPropTypes,
};

ArticleTeaseSmall.propTypes = {
  article: articleStubPropTypes,
};

export { ArticleTeaseBig, ArticleTeaseSmall };
