import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { className as cn } from 'utils';
import AnimationLink from './TransitionLink';
import { Link as GatsbyLink } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import styles from './link.module.scss';

// Avoid jest babel issue with parsing gsap plugins
const TransitionLink = process.env.NODE_ENV === 'test' ? GatsbyLink : AnimationLink;

//Remove trailing slashes except for homepage
export const removeTrailingSlash = url => {
  let newUrl;
  if (url && url !== '/') {
    newUrl = url.replace(/\/$/, '');
  } else {
    newUrl = url;
  }
  return newUrl;
};

// eslint-disable-next-line react/display-name
const Link = forwardRef(
  (
    {
      id,
      activeClassName,
      ariaLabel,
      children,
      className,
      newTab,
      onClick,
      onBlur,
      onKeyDown,
      onKeyUp,
      partiallyActive,
      to,
      disabled = false,
      noFocus = false,
    },
    ref,
  ) => {
    const location = useLocation();
    const jumpLink = /#/.test(to) && !/^#$/.test(to);
    const isInternal = /^\/(?!\/)/.test(to);
    const isFile = /\.[0-9a-z]+$/i.test(to);
    const isXMLFile = /.xml/.test(to);
    const isInternalLink = useMemo(() => {
      return isInternal && !isFile && !jumpLink;
    }, [to]);

    const props = {
      id,
      onClick,
      onBlur,
      onKeyDown,
      onKeyUp,
      ref,
      ...cn(disabled && styles.disabled, className),
    };

    if (ariaLabel) {
      props['aria-label'] = ariaLabel;
    }

    if (noFocus) {
      props.tabIndex = -1;
    }

    if (isInternalLink && !jumpLink && !disabled) {
      return (
        <TransitionLink
          to={removeTrailingSlash(to)}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...props}>
          {children}
        </TransitionLink>
      );
    }

    if ((!isInternalLink || newTab) && !jumpLink) {
      props.target = '_blank';
      props.rel = 'noopener noreferrer';
    }

    if (jumpLink) {
      const samePage = to.startsWith('#');

      return samePage ? (
        <AnchorLink {...props} to={`${location.pathname}${to}`}>
          {children}
        </AnchorLink>
      ) : (
        <AnchorLink {...props} to={to} stripHash>
          {children}
        </AnchorLink>
      );
    }

    if (isXMLFile) {
      const showFile = file => {
        fetch(file, {
          method: 'GET',
        })
          .then(response => response.blob())
          .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = file.substring(file.lastIndexOf('/') + 1);
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
          });
      };

      return (
        <a {...props} onClick={() => showFile(to)}>
          {children}
        </a>
      );
    }

    if (!disabled) {
      props.href = to;
    }

    return <a {...props}>{children}</a>;
  },
);

Link.propTypes = {
  activeClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  id: PropTypes.string,
  partiallyActive: PropTypes.bool,
  to: PropTypes.string.isRequired,
  noFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

Link.defaultProps = {
  activeClassName: '',
  className: '',
  newTab: false,
};

export default Link;
