import CanvasStripe from './canvas-object-stripe';

class Cover {
  constructor() {
    //objects
    this.obj = [];

    //create the stripe objects
    //there are two parts
    Array.from(document.querySelectorAll('.gl_object_stripe')).forEach(e => {
      //this is the part that doesn't rotate
      this.obj.push(new CanvasStripe(e, false));
      //this is the part that rotates on the y-axis
      this.obj.push(new CanvasStripe(e, true));
    });
  }

  init() {
    //return our meshes to the main canvas element
    return this.obj;
  }

  exit() {
    this.obj.forEach(e => {
      e.dispose();
    });
  }

  resize() {
    this.obj.forEach(e => {
      e.resize();
    });
  }

  rAF(x, y, p) {
    this.obj.forEach(e => e.rAF(x, y, p));
  }
}

export default Cover;
