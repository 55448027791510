/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { isNonEmptyArray } from 'utils';
import { BodyContent, ButtonLink, Link, MediaLayout } from 'components';
import styles from './solutions.module.scss';
import OverflowRight from 'components/CTA/OverflowRight';
import SolutionColumns from './SolutionColumns';

const Solutions = ({
  headline,
  intro,
  link,
  button,
  headline2,
  intro2,
  link2,
  button2,
  solutions,
  valueProp,
  content,
  overflowHeroContent,
  solutionColumns,
  showRightMenu,
}) => (
  <div className="page-left-inset page-left-inset-content">
    <section className={styles.outerWrapper}>
      <section className={styles.innerWrapper}>
        {overflowHeroContent?.map(({ link, buttonText, media, caption, contentful_id }) => (
          <OverflowRight
            link={link}
            buttonText={buttonText}
            media={media}
            caption={caption.json}
            key={contentful_id}
          />
        ))}
        {valueProp && (
          <MediaLayout
            className={[styles.backsplash, styles.leftContent]}
            mainContentClass={styles.homeLeftCap}
            imgHeight={600}
            imgWidth={600}
            bleedClass={styles.homeBleed}
            media={valueProp.media}
            caption={valueProp.caption}
            type={'Left Cap'}
          />
        )}
      </section>
      {(headline || intro?.solutionsIntro || link || button || headline2 || intro2?.solutionsIntro2 || link2 || button2) && (
        <section className={styles.sectionWrapper}>
          <div className={styles.leftContent}>
            {headline && <h1 className={styles.headline}>{headline}</h1>}

            {intro?.solutionsIntro && <p className={styles.intro}>{intro?.solutionsIntro}</p>}
            {link && (
              <Link to={link} className={styles.button}>
                Learn More
              </Link>
            )}
            {button && <ButtonLink className={styles.buttonLink} {...button} />}
            {headline2 && <h1 className={styles.headline}>{headline2}</h1>}
            {intro2?.solutionsIntro2 && <p className={styles.intro}>{intro2.solutionsIntro2}</p>}
            {link2 && (
              <Link to={link2} className={styles.button}>
                Learn More
              </Link>
            )}
            {button2 && <ButtonLink className={styles.buttonLink} {...button2} />}
          </div>
          {(showRightMenu !== false) && (
            <div className={styles.listWrapper}>
              <ul className={styles.list}>
                {isNonEmptyArray(solutions) &&
                  solutions.map((solution, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link className={styles.listItemText} to={`/${solution.slug}`}>
                        <span className={styles.number}>0{i + 1}</span>
                        <h2 className={styles.listHeadline}>
                          <span className={styles.transformWrapper}>{solution.title}</span>
                        </h2>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </section>
      )}
      {solutionColumns && (
        <SolutionColumns solutions={solutionColumns} />
      )}
      {content && <BodyContent content={content} className={styles.homePageContent} />}
    </section>
  </div>
);
Solutions.propTypes = {
  headline: PropTypes.string.isRequired,
  intro: PropTypes.shape({
    solutionsIntro: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.string.isRequired,
  button: PropTypes.shape({
    buttonLabel: PropTypes.string,
    buttonLink: PropTypes.string,
  }),
  headline2: PropTypes.string.isRequired,
  intro2: PropTypes.shape({
    solutionsIntro2: PropTypes.string.isRequired,
  }).isRequired,
  link2: PropTypes.string.isRequired,
  button2: PropTypes.shape({
    buttonLabel: PropTypes.string,
    buttonLink: PropTypes.string,
  }),
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  ),
  valueProp: PropTypes.shape({
    media: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    caption: PropTypes.shape({
      json: PropTypes.object.isRequired,
    }),
  }),
  overflowHeroContent: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      caption: PropTypes.shape({
        json: PropTypes.object.isRequired,
      }),
      link: PropTypes.string,
      buttonText: PropTypes.string,
    }),
  ),
  showRightMenu: PropTypes.bool,
  solutionColumns: PropTypes.object,
};

export default Solutions;
