import React from 'react';
import PropTypes from 'prop-types';
import styles from './bookmark.module.scss';

const Bookmark = ({ id }) => <a id={id} className={styles.bookmark}></a>;

Bookmark.propTypes = {
  id: PropTypes.string,
};

export default Bookmark;
