import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from '../ArticleListItem/articleListItem.module.scss';

const Intro = ({ introHeadline, introText }) => (
  <li {...className(styles.item, styles.indexIntroContainer, 'article-index-intro-container')}>
    <div className={styles.text}>
      <h1 className={styles.title}>{introHeadline}</h1>
      {introText && <p className={styles.intro}>{introText.introText}</p>}
    </div>
  </li>
);

Intro.propTypes = {
  introHeadline: PropTypes.string,
  introText: PropTypes.object,
};

export default Intro;
