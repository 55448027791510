import React from 'react';
import PropTypes from 'prop-types';

const YoutubeLogo = ({ className: customClassName }) => (
  <svg className={customClassName} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M200,0 C310.45695,0 400,89.54305 400,200 C400,310.45695 310.45695,400 200,400 C89.54305,400 0,310.45695 0,200 C0,89.54305 89.54305,0 200,0 Z M200.559577,114.00064 L199.440702,114.00064 C192.462686,114.012802 122.226682,114.256045 103.992727,119.120909 C93.4381818,121.935455 85.1118182,130.261818 82.2581818,140.855455 C77.3933182,159.052273 77.150075,196.053089 77.1379128,199.707678 L77.1379128,200.292322 C77.150075,203.946911 77.3933182,240.947727 82.2581818,259.144545 C85.0727273,269.699091 93.3990909,278.025455 103.992727,280.879091 C123.186364,286 200,286 200,286 C200,286 276.852727,286 296.007273,280.879091 C306.561818,278.064545 314.888182,269.738182 317.741818,259.144545 C322.606682,240.947727 322.849925,203.946911 322.862087,200.292322 L322.862087,199.707678 C322.849925,196.053089 322.606682,159.052273 317.741818,140.855455 C314.927273,130.300909 306.600909,121.974545 296.007273,119.120909 C277.810455,114.256045 207.541027,114.012802 200.559577,114.00064 Z"
        fill="currentColor"></path>
      <g transform="translate(174.304348, 161.000000)" fill="currentColor" fillRule="nonzero">
        <polygon points="0.596245059 77.1486364 66.6585178 39 0.596245059 0.851363636"></polygon>
      </g>
    </g>
  </svg>
);

YoutubeLogo.propTypes = {
  className: PropTypes.string,
};

export default YoutubeLogo;
