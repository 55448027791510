import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <circle cx="13" cy="9" r="7.5" stroke="currentColor" strokeWidth="3" />
    <path d="M2 20L7 15" stroke="currentColor" strokeWidth="3" />
  </svg>
);

SearchIcon.propTypes = {
  className: PropTypes.string,
};

export default SearchIcon;
