import styles from './navigation.module.scss';
import PropTypes from 'prop-types';
import React from 'react';

const Navigation = ({ next, prev, carouselTitleId }) => (
  <nav className={styles.nav} aria-labelledby={carouselTitleId}>
    <button aria-label="Previous Slide" onClick={prev} className={styles.leftStep}>
      <span>&larr;</span>
    </button>
    <button aria-label="Next Slide" onClick={next} className={styles.rightStep}>
      <span>&rarr;</span>
    </button>
  </nav>
);

Navigation.propTypes = {
  carouselTitleId: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
};

export default Navigation;
