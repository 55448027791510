import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

import { getQueryParams } from 'utils';

const SEO = ({ description, pathname, shareImage, title }) => {
  const { global } = useStaticQuery(
    graphql`
      fragment SEO on ContentfulSeo {
        description {
          description
        }
        shareImage {
          file {
            url
          }
          title
        }
        title
      }

      query GlobalQuery {
        global: contentfulGlobal {
          seo {
            ...SEO
          }
          name
          url
          twitterHandle
        }
      }
    `,
  );

  const { seo } = global;

  const metaTitle = useMemo(() => (title ? title : seo.title), [title, seo.title]);

  const metaDescription = useMemo(
    () =>
      description && description.description
        ? description.description
        : seo.description.description,
    [description],
  );

  const metaUrl = useMemo(() => `${global.url}${pathname}`, [pathname, global.url]);

  const metaImage = useMemo(() => shareImage || seo.shareImage, [shareImage, seo.shareImage]);
  const metaImageSrc = useMemo(
    () =>
      metaImage && metaImage.file
        ? `https:${metaImage.file.url}${getQueryParams({ w: 1200, fit: 'fill' })}`
        : '',
    [metaImage],
  );
  const metaImageAlt = useMemo(() => (metaImage && metaImage.file ? metaImage.title : ''), [
    metaImage,
  ]);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      link={
        metaUrl && [
          {
            rel: `canonical`,
            href: metaUrl,
          },
        ]
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        /* OG Meta */
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:site_name`,
          content: global.name,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        /* Twitter Meta */
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@${global.twitterHandle}`,
        },
        /* OG and Twitter Meta */
        ...['og', 'twitter'].flatMap(type => [
          {
            property: `${type}:description`,
            content: metaDescription,
          },
          {
            property: `${type}:image`,
            content: metaImageSrc,
          },
          {
            property: `${type}:image:alt`,
            content: metaImageAlt,
          },
          {
            property: `${type}:title`,
            content: metaTitle,
          },
          {
            property: `${type}:url`,
            content: metaUrl,
          },
        ]),
      ]}
    />
  );
};

SEO.propTypes = {
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  pathname: PropTypes.string.isRequired,
  shareImage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
  title: PropTypes.string,
};

export default SEO;
