import React from 'react';
import PropTypes from 'prop-types';
import { className, renderText } from 'utils';
import styles from './articleDisclosure.module.scss';

const ArticleDisclosure = ({ className: customClassName, disclosureText }) =>
  disclosureText && (
    <div {...className(customClassName, 'page-left-inset', 'page-left-inset-content')}>
      <section className={styles.articleDisclosure}>
        <div className={styles.inner}>{renderText(disclosureText)}</div>
      </section>
    </div>
  );

ArticleDisclosure.propTypes = {
  className: PropTypes.string,
  disclosureText: PropTypes.object,
};

export default ArticleDisclosure;
