import { gsap, TweenMax, Power1, Power4 } from 'gsap';
import { PAGE_ANIMATION_DURATION } from 'utils/constants';

const homeAnimationIn = () => {
  if (window.scrollY > 0) {
    gsap.to(window, {
      scrollTo: 0,
      duration: PAGE_ANIMATION_DURATION,
      ease: Power4.easeOut,
    });
  }

  TweenMax.staggerFromTo(
    document.querySelectorAll('.js-animate-text'),
    0.6,
    { autoAlpha: 0, transform: 'translateY(10px)' },
    {
      autoAlpha: 1,
      transform: 'translateY(0)',
      ease: Power1.easeOut,
      transformOrigin: '0% 100%',
      delay: 1,
    },
    0.2,
  );

  TweenMax.staggerFromTo(
    document.querySelectorAll('.js-animate-profiles'),
    0.6,
    { x: window.innerHeight * 0.1, opacity: 0 },
    { x: 0, opacity: 1, ease: Power1.easeOut, delay: 1.2 },
    0.1,
  );

  TweenMax.to(
    document.querySelectorAll('#js-animate-content'),
    0.8,
    { y: 0, autoAlpha: 1, ease: Power1.easeOut, delay: 1 },
    0.05,
  );
};

const navAnimation = () => {
  TweenMax.to('#logo', 1, { autoAlpha: 1 });
  TweenMax.to('#js-animate-nav', 1, { autoAlpha: 1 });
};

const scrollToTheTop = () => {
  if (window.scrollY > 0) {
    gsap.to(window, {
      scrollTo: 0,
      duration: PAGE_ANIMATION_DURATION,
      ease: Power4.easeOut,
    });
  }
};

const pageAnimationOut = (node, isMobileMenuOpen = false) => {
  gsap.to(node, { duration: PAGE_ANIMATION_DURATION, opacity: 0 });

  // If the mobile menu is open, scroll to the top first
  if (isMobileMenuOpen) {
    scrollToTheTop();
  }
};

const pageAnimationIn = node => {
  scrollToTheTop();

  TweenMax.staggerFromTo(
    node,
    0.8,
    { autoAlpha: 0, ease: Power1.easeIn },
    { autoAlpha: 1, ease: Power1.easeIn },
    0.02,
  );
};

export { homeAnimationIn, navAnimation, pageAnimationOut, pageAnimationIn };
