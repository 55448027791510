import React from 'react';
import PropTypes from 'prop-types';

import { useHubspotForm } from 'hooks';
import { className } from 'utils';
import styles from './emailForm.module.scss';

const EmailForm = ({ className: customClassName, formId }) => {
  const divId = 'hubspot-email-form';
  useHubspotForm(formId, `#${divId}`);

  return <div id={divId} {...className(styles.emailForm, customClassName)}></div>;
};

EmailForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
};

export default EmailForm;
