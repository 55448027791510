import React from 'react';
import PropTypes from 'prop-types';
import differenceBy from 'lodash.differenceby';
import { ArticleTeaseBig, ArticleTeaseSmall } from './ArticleTeases';
import { Link } from 'components';
import { BASE_PATHS, isNonEmptyArray } from 'utils';
import { articleStubPropTypes } from 'utils/proptypes';
import styles from './insights.module.scss';

const Insights = ({ headline, featuredInsights, insights }) => {
  const featuredInsightsBig = featuredInsights.slice(0, 2);
  let featuredInsightsSmall = featuredInsights.slice(2);

  const numSmallInsights = featuredInsightsSmall.length;
  if (numSmallInsights < 3) {
    const recentInsights = differenceBy(insights, featuredInsights, 'title').slice(0, 3 - numSmallInsights);
    featuredInsightsSmall = [...featuredInsightsSmall, ...recentInsights];
  }

  return (
    <section className={styles.insights}>
      <div className="page-left-inset page-left-inset-content">
        <div className={styles.content}>
          <h1 className={styles.head}>{headline}</h1>
          <div className={styles.teases}>
            <div className={styles.bigTeases}>
              {isNonEmptyArray(featuredInsightsBig) &&
                featuredInsightsBig.map((insight, i) => <ArticleTeaseBig article={insight} key={i} />)}
            </div>
            <div className={styles.smallTeases}>
              {isNonEmptyArray(featuredInsightsSmall) &&
                featuredInsightsSmall.map((insight, i) => <ArticleTeaseSmall article={insight} key={i} />)}
              <div className={styles.ctaWrap}>
                <Link className={styles.cta} to={`/${BASE_PATHS.insights}`}>
                  All Insights
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Insights.propTypes = {
  headline: PropTypes.string.isRequired,
  featuredInsights: PropTypes.arrayOf(articleStubPropTypes),
  insights: PropTypes.arrayOf(articleStubPropTypes),
};

export default Insights;
