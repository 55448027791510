import React from 'react';
import PropTypes from 'prop-types';
import { className, isNonEmptyArray } from 'utils';
import styles from './grid.module.scss';
import BioGridItem from './BioGridItem';

const Grid = props => {
  const { items } = props;

  return (
    <ul {...className('component', styles.wrapper)}>
      {isNonEmptyArray(items) &&
        items.map((data, i) => <BioGridItem key={i} {...data} />)}
    </ul>
  );
};

Grid.propTypes = {
  items: PropTypes.array,
};

export default Grid;
