import React, { Fragment } from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { safeGet, renderText, className } from 'utils';
import { imagePropTypes, videoPropTypes } from 'utils/proptypes';
import { carouselImageParams } from 'utils/imageQueryParams';
import styles from './slide.module.scss';
import { VideoPlayer } from 'components';
import { Link } from 'gatsby';

const Slide = ({ image, link, caption, video, isVideoCarousel }) => {
  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    image,
    'title',
    'description',
    ['file', 'url'],
  );

  const jsonCaption = get(caption, 'json', caption);

  if (isEmpty(imageUrl || video)) {
    return null;
  }

  const imageContent = (
    <Fragment>
      <div className={styles.imageWrapper}>
        <div className={styles.clippingBox}>
          <img
            className={styles.image}
            src={`${imageUrl}${carouselImageParams}`}
            alt={imageDescription || imageTitle}
          />
        </div>
      </div>
      {jsonCaption && (
        <figcaption className={styles.caption}>{renderText({ json: jsonCaption })}</figcaption>
      )}
    </Fragment>
  );

  if (isVideoCarousel) {
    return (
      <figure {...className(styles.slide, styles.isVideoCarousel)}>
        {link ? (
          <Link to={link} className={styles.linkWrapper}>
            {imageContent}
          </Link>
        ) : (
          image && imageContent
        )}
        <div {...className(styles.imageWrapper, styles.videoCarousel)}>
          <div className={styles.clippingBox}>
            <VideoPlayer
              {...video}
              url={video.url}
              cover={video.coverImage}
              isVideoCarousel={isVideoCarousel}
            />
          </div>
        </div>
        {jsonCaption && (
          <figcaption className={styles.caption}>{renderText({ json: jsonCaption })}</figcaption>
        )}
      </figure>
    );
  } else {
    return (
      <figure className={styles.slide}>
        {link ? (
          <Link to={link} className={styles.linkWrapper}>
            {imageContent}
          </Link>
        ) : (
          imageContent
        )}
      </figure>
    );
  }
};

Slide.propTypes = {
  caption: PropTypes.object,
  image: imagePropTypes.isRequired,
  link: PropTypes.string,
  video: videoPropTypes,
  isVideoCarousel: PropTypes.bool,
};

export default Slide;
