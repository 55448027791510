import React from 'react';
import PropTypes from 'prop-types';
import { isNonEmptyArray } from 'utils';
import { articleStubPropTypes } from 'utils/proptypes';
import ArticleListItem from './ArticleListItem';
import ArticleListIntro from './ArticleListIntro';

const ArticleList = ({
  articles,
  withIntro,
  introHeadline,
  introText,
  className: customClassName,
}) => (
  <ul className={customClassName}>
    {withIntro && <ArticleListIntro introHeadline={introHeadline} introText={introText} />}
    {isNonEmptyArray(articles) &&
      articles.map((article, i) => <ArticleListItem key={i} article={article} />)}
  </ul>
);

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      node: articleStubPropTypes,
    }),
  ),
  withIntro: PropTypes.bool,
  introHeadline: PropTypes.string,
  introText: PropTypes.object,
  className: PropTypes.string,
};

export default ArticleList;
