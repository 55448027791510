import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { carouselItemsPropTypes } from 'utils/proptypes';
import { Carousel } from 'components';
import Slide from '../Slide';
import TopRightButton from '../TopRightButton';
import styles from './careersCarousel.module.scss';

const CareersCarousel = ({ carouselItems, title, description, buttonText, buttonLink, name }) =>
  isEmpty(carouselItems) ? null : (
    <Carousel
      title={title}
      className={styles.careersCarousel}
      description={description}
      name={name}
      topRightButton={<TopRightButton text={buttonText} link={buttonLink} />}>
      {carouselItems.map(({ image, link, caption }, i) => (
        <Slide key={i} image={image} link={link} caption={caption} />
      ))}
    </Carousel>
  );

CareersCarousel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  carouselItems: carouselItemsPropTypes.isRequired,
  name: PropTypes.string.isRequired,
};

export default CareersCarousel;
