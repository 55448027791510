import { useState } from 'react';
import { INLINES } from '@contentful/rich-text-types';
import renderText, { renderEntryHyperlink, renderHyperlink } from 'utils/renderText';

const useLinks = linkRichText => {
  /**
   * Retrieves the links to render from the the links
   * body in the Contentful Site model.
   *
   * @returns {object[]} the nav links array
   */
  const getLinks = () => {
    const links = [];

    /**
     * Adds a link to the links array.
     *
     * @param {string|string[]} text the text to display
     * @param {string} to the link to redirect to
     */
    const addLink = (to, text) => links.push({ text, to });

    renderText(linkRichText, {
      renderNode: {
        [INLINES.ENTRY_HYPERLINK]: renderEntryHyperlink(addLink),
        [INLINES.HYPERLINK]: renderHyperlink(addLink),
      },
    });

    return links;
  };

  const [links] = useState(getLinks());

  return links;
};

export default useLinks;
