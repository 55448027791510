import React from 'react';
import { className, safeGet, getQueryParams } from 'utils';
import styles from './imageCTAGridItem.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components';

const ImageCTAGridItem = props => {
  const {
    url: imageURL,
    description: imageDescription,
    title: imageTitle,
    headline,
    intro,
    label,
    ctaUrl,
    labelUrl,
    videoIcon,
  } = safeGet(
    props,
    ['image', 'file', 'url'],
    ['image', 'title'],
    ['image', 'description'],
    'label',
    'headline',
    'intro',
    'ctaUrl',
    'labelUrl',
    'videoIcon',
  );

  const { title: videoIconTitle, url: videoIconURL, description: videoIconDescription } = safeGet(
    videoIcon,
    'title',
    ['file', 'url'],
    'description',
  );

  if (!headline) {
    return null;
  }

  return (
    <li {...className(styles.item, styles.itemWithImage)}>
      <div className={styles.itemInner}>
        <div className={styles.featuredImgWrapper}>
          <div className={styles.clippingBox}>
            {ctaUrl ? (
              <Link to={ctaUrl}>
                <img
                  className={styles.featuredImg}
                  src={`${imageURL}${getQueryParams({ w: 400, h: 275, fit: 'fill' })}`}
                  alt={imageDescription || imageTitle}
                />
                {videoIconURL && (
                  <img
                    className={styles.videoIcon}
                    src={`${videoIconURL}${getQueryParams({ w: 30, h: 30, fit: 'fill' })}`}
                    alt={videoIconDescription || videoIconTitle}
                  />
                )}
              </Link>
            ) : (
              <img
                className={styles.featuredImg}
                src={`${imageURL}${getQueryParams({ w: 400, h: 275, fit: 'fill' })}`}
                alt={imageDescription || imageTitle}
              />
            )}
          </div>
        </div>
        <div className={styles.text}>
          {label && (
            <span className={styles.topic}>
              {labelUrl ? (
                <Link to={labelUrl} className={styles.topicLink}>
                  {label}
                </Link>
              ) : (
                <span className={styles.topicLink}>{label}</span>
              )}
            </span>
          )}
          <h1 className={styles.title}>
            {ctaUrl ? (
              <Link to={ctaUrl} className={styles.titleLink}>
                {headline}
              </Link>
            ) : (
              headline
            )}
          </h1>

          {intro && <p className={styles.intro}>{intro}</p>}
        </div>
      </div>
    </li>
  );
};

ImageCTAGridItem.propTypes = {
  props: PropTypes.object,
};

export default ImageCTAGridItem;
