import React from 'react';
import { className } from 'utils';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { UNICODE } from 'utils/constants';
import styles from './paginate.module.scss';

const Paginate = ({
  className: customClassName,
  currentPage,
  numPages,
  basePath,
  onPaginatePrev,
  onPaginateNext,
}) => {
  const nextLink = `${basePath}/${currentPage + 1}`;
  const prevLink = currentPage === 2 ? basePath : `${basePath}/${currentPage - 1}`;

  return (
    <nav aria-label="Pagination" {...className(styles.paginate, customClassName)}>
      <span className={styles.pageNumber}>{`Page ${currentPage} of ${numPages}`}</span>
      <span className={styles.arrows}>
        <Link
          {...className(styles.leftStep, currentPage === 1 && styles.disabled)}
          to={!onPaginatePrev ? prevLink : '#'}
          disabled={currentPage === 1}
          onClick={onPaginatePrev}>
          <span className={styles.transformWrapper}>{UNICODE.LEFT_ARROW}</span>
        </Link>
        <Link
          {...className(styles.rightStep, currentPage === numPages && styles.disabled)}
          to={!onPaginateNext ? nextLink : '#'}
          disabled={currentPage === numPages}
          onClick={onPaginateNext}>
          <span className={styles.transformWrapper}>{UNICODE.RIGHT_ARROW}</span>
        </Link>
      </span>
    </nav>
  );
};

Paginate.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  basePath: PropTypes.string.isRequired,
  onPaginatePrev: PropTypes.func,
  onPaginateNext: PropTypes.func,
};

export default Paginate;
