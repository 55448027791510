import React from 'react';
import PropTypes from 'prop-types';

const LinkedInLogo = ({ className: customClassName }) => (
  <svg
    className={customClassName}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 346.1223 346.1223">
    <path
      fill="currentColor"
      d="M173.0612,0A173.0612,173.0612,0,1,0,346.1223,173.0612,173.0612,173.0612,0,0,0,173.0612,0Zm-51.81,263.2413h-37.79V141.3365h37.79ZM101.7469,124.27A21.9428,21.9428,0,1,1,123.69,102.327,21.8516,21.8516,0,0,1,101.7469,124.27ZM226.09,263.2413V203.5079c0-14.6285,0-32.9142-19.5047-32.9142s-23.1619,15.8476-23.1619,31.6952v60.9524h-37.79V141.3365h36.5714v17.0667c7.3142-12.19,20.7237-20.7238,35.3524-19.5048,39.0094,0,45.1047,25.6,45.1047,58.5143l1.219,65.8286Z"
    />
  </svg>
);

LinkedInLogo.propTypes = {
  className: PropTypes.string,
};

export default LinkedInLogo;
