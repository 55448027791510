import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './insetRight.module.scss';
import { className, renderText, safeGet, getQueryParams } from 'utils';
import { imagePropTypes } from 'utils/proptypes';

const InsetRight = ({ caption, media, link, className: customClassName }) => {
  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    media,
    [0, 'title'],
    [0, 'description'],
    [0, 'file', 'url'],
  );

  if (!imageUrl) {
    return null;
  }

  const imageContent = (
    <img src={`${imageUrl}${getQueryParams({ w: 350 })}`} alt={imageDescription || imageTitle} />
  );

  const imageComponent = link ? <Link to={link}>{imageContent}</Link> : imageContent;

  return (
    <div {...className(customClassName, styles.insetRight)}>
      <figure className={styles.figure}>
        {imageComponent}
        {caption && (
          <figcaption className={styles.caption}>{renderText({ json: caption })}</figcaption>
        )}
      </figure>
    </div>
  );
};

InsetRight.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.object,
  media: PropTypes.arrayOf(imagePropTypes),
  link: PropTypes.string,
};

export default InsetRight;
