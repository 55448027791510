import React from 'react';
import BleedRight from '../BleedRight';
import PropTypes from 'prop-types';
import { className, renderText } from 'utils';
import { imagePropTypes } from 'utils/proptypes';
import styles from './leftCap.module.scss';

const LeftCap = ({
  caption,
  media,
  link,
  className: customClassName,
  mainContentClass,
  bleedClass,
  imgHeight,
  imgWidth,
}) => (
  <div {...className('component', styles.leftCap, mainContentClass && mainContentClass)}>
    {caption && (
      <div
        {...className(
          customClassName && customClassName[0],
          customClassName && customClassName[1],
          !customClassName && styles.leftContent,
          'left-cap-caption',
        )}>
        {renderText({ json: caption })}
      </div>
    )}
    <BleedRight
      media={media}
      link={link}
      className={className(styles.leftCapBleedRight, bleedClass).className}
      imgHeight={imgHeight}
      imgWidth={imgWidth}
    />
  </div>
);

LeftCap.propTypes = {
  className: PropTypes.array,
  caption: PropTypes.object,
  media: PropTypes.arrayOf(imagePropTypes),
  mainContentClass: PropTypes.string,
  bleedClass: PropTypes.string,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  link: PropTypes.string,
};

export default LeftCap;
