import React, { forwardRef, useEffect, useState, useRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { className, ease, getQueryParams } from 'utils';
import { KEY_CODES, MENU_TYPE_FORMAT } from 'utils/constants';
import { Link } from 'components';
import { useDetectIE } from 'hooks';
import Popover from '@reach/popover';
import isEmpty from 'lodash.isempty';
import styles, {
  varDropdownWidthEm,
  varDropdownVertShiftEm,
  varTransitionDuration,
} from './dropdown.module.scss';
import baseNavStyles from '../../navigation.module.scss';
import { MediumRight } from 'svgs';

const Dropdown = forwardRef(({ menu, focusNext, focusPrevious, isHoldingShift }, ref) => {
  let isNavigating = false;
  const [isOpen, setIsOpen] = useState(false); // Controls whether the dropdown element is in the DOM
  const [isVisible, setIsVisible] = useState(false); // Controls whether the dropdown element has the class that makes it visible
  const [isOnParentElement, setIsOnParentElement] = useState(true); // Set to know if we should move to the next dropdown
  const itemRef = useRef(null);
  const [hasFocused, setHasFocused] = useState(false); // Set so we know whether to tab into the menu
  const VAR_TRANSITION_DURATION = parseInt(varTransitionDuration);
  const VAR_DROPDOWN_WIDTH_EM = parseInt(varDropdownWidthEm);
  const VAR_DROPDOWN_VERT_SHIFT_EM = `${varDropdownVertShiftEm}`;
  const VAR_MIN_PADDING = 10;

  const isIE = useDetectIE();

  const headingLinkRef = useRef(null);
  const [popoverWrapperTransform, setPopoverWrapperTransform] = useState(0);
  const [popoverArrowStyle, setPopoverArrowStyle] = useState({});

  const [visibleTimer, setVisibleTimer] = useState(null);
  const [hiddenTimer, setHiddenTimer] = useState(null);

  const dropdownRef = useRef(null);

  useImperativeHandle(ref, () => headingLinkRef.current);

  const showMenu = () => {
    if (hiddenTimer) {
      clearTimeout(hiddenTimer);
    }

    // Mount the element
    setIsOpen(true);

    // Once in the DOM, transition from hidden > visible
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10);

    setVisibleTimer(timer);
  };

  const hideMenu = () => {
    if (visibleTimer) {
      clearTimeout(visibleTimer);
    }

    // Begin transition from visible > hidden
    setIsVisible(false);

    // Once the transition is complete, unmount the element
    const timer = setTimeout(() => {
      setIsOpen(false);
    }, VAR_TRANSITION_DURATION);

    setHiddenTimer(timer);
  };

  const closeOutMenu = () => {
    hideMenu();
    setHasFocused(false);
  };

  const handleEscape = event => {
    if (event.keyCode === KEY_CODES.ESCAPE) {
      closeOutMenu();
    }
  };

  /**
   * If the user hits escape, close the menu
   */
  useEffect(() => {
    document.body.addEventListener('keyup', handleEscape);

    return () => {
      document.body.removeEventListener('keyup', handleEscape);
    };
  }, []);

  const navigateMenuItem = () => {
    isNavigating = true;
    closeOutMenu();
    document.activeElement.blur();
    setTimeout(() => (isNavigating = false), 1200);
  };

  const focusList = () => {
    if (isOnParentElement && isHoldingShift) {
      closeOutMenu();
      return;
    }

    if (hasFocused) {
      return;
    }

    if (dropdownRef.current) {
      dropdownRef.current.querySelector('a').focus();
      setHasFocused(true);
      setIsOnParentElement(false);
    }
  };

  const navigableItems = menu.menuLinks.filter(link => link.text.join('').trim());

  const focusNextItem = index => {
    if (isNavigating) {
      return;
    }
    if (isHoldingShift && index === 0) {
      focusPrevious();
      setHasFocused(false);
      setIsOnParentElement(true);
    } else if (index + 1 >= navigableItems.length) {
      focusNext();
      closeOutMenu();
      setIsOnParentElement(true);
    }
  };

  useEffect(() => {
    // The difference in width between the menu
    // link text and dropdown, divided by 2.
    // Centers the dropdown below the link.

    const halfTranslateX = (VAR_DROPDOWN_WIDTH_EM - headingLinkRef.current.getBoundingClientRect().width) / 2;
    const { left } = headingLinkRef.current.getBoundingClientRect() || {};
    const marginLeft = left - halfTranslateX;
    const marginRight = window.innerWidth - left - VAR_DROPDOWN_WIDTH_EM + halfTranslateX;

    let dx = 0;
    if (marginLeft < 0) {
      dx = marginLeft - VAR_MIN_PADDING;
    }

    if (marginRight < 0) {
      dx = - marginRight + VAR_MIN_PADDING * 2;
    }

    const centeredWrapperTranslateX = halfTranslateX + dx;

    if (isIE) {
      setPopoverWrapperTransform({
        transform: `translateX(${-centeredWrapperTranslateX}px)`,
        opacity: `${isVisible ? '1' : '0'}`,
      });
    } else {
      setPopoverWrapperTransform({
        transform: `translate(${-centeredWrapperTranslateX}px, ${
          isVisible ? '0' : VAR_DROPDOWN_VERT_SHIFT_EM
        })`,
        opacity: `${isVisible ? '1' : '0'}`,
      });
    }
    setPopoverArrowStyle({
      left: `calc(50% - 10px + ${dx}px)`, 
    });
  }, [headingLinkRef.current, isVisible, isOpen]);

  const calcPosition =  (targetRect, popoverRect) => {
    if (!targetRect || !popoverRect) {
      return {};
    }

    return {
      left: `${targetRect.left + window.pageXOffset}px`,
      top: `${targetRect.top + targetRect.height + window.pageYOffset}px`,
    };
  };

  const ctaImage = useMemo(
    () =>
      menu?.cta1NavigationImage.file
        ? `https:${menu?.cta1NavigationImage.file.url}${getQueryParams({ w: 396, fit: 'fill' })}`
        : '',
    [menu?.cta1NavigationImage],
  );

  return (
    <li
      ref={itemRef}
      className={styles.navLink}
      onFocus={showMenu}
      onBlur={focusList}
      onMouseOver={showMenu}
      onMouseLeave={hideMenu}>
      <Link
        activeClassName={baseNavStyles.activeLink}
        className={baseNavStyles.link}
        onClick={navigateMenuItem}
        ref={headingLinkRef}
        to={menu.headingUrl}
        partiallyActive>
        {menu.heading}
      </Link>
      {isOpen && !isEmpty(navigableItems) && (
        <Popover targetRef={itemRef} position={calcPosition} style={popoverWrapperTransform}>
          <div
            ref={dropdownRef}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
            {...className(
              isIE && styles.isIe,
              isVisible && styles.isVisible,
              styles.dropdownWrapper,
            )}>
            <div className={styles.popoverArrow} style={popoverArrowStyle}/>
            <div className={styles.dropdownLeft}>
              {menu.dropdownHeading && (
                <h3 className={styles.dropdownHeading}>{menu.dropdownHeading}</h3>
              )}
              <ul data-label={menu.heading}>
                {navigableItems.map((link, index) => (
                  <li
                    key={index}
                    className={styles.dropdownLinkWrapper}
                    style={{
                      transitionDuration: `${ease.inCubic((index + 1) / navigableItems.length) * 600 +
                          350}ms`,
                    }}>
                    <Link
                      tabIndex={index}
                      onBlur={event => focusNextItem(index, event)}
                      to={link.to}
                      onClick={navigateMenuItem}
                      className={styles.dropdownLink}>
                      {menu?.menuFormatType === MENU_TYPE_FORMAT.Number && (
                        <span className={styles.prefix}>{`0${index + 1}`}</span>
                      )}
                      <span className={styles.linkTextContent}>{link.text}</span>
                      <div className={styles.dropdownLinkIntro}>{link.description}</div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.dropdownRight}>
              <div className={styles.promo}>
                <Link
                  to={menu?.cta1NavigationLink}
                  className={styles.ctaImageLink}>
                  {ctaImage && <img src={ctaImage} alt={menu?.cta1NavigationImage?.title || ''} />}
                </Link>
                <p>{menu?.cta1NavigationIntro}</p>
                <Link
                  to={menu?.cta1NavigationLink}
                  className={styles.ctaLink}>
                  <div className={styles.buttonText}>{menu?.cta1NavigationButtonText}</div>
                  <MediumRight />
                </Link>
              </div>
              {menu?.cta2NavigationIntro && menu?.cta2NavigationLink && (
                <div {...className(
                  styles.promo,
                  styles.borderTop,
                )}>
                  <p>{menu?.cta2NavigationIntro}</p>
                  <Link
                    to={menu?.cta2NavigationLink}
                    className={styles.ctaLink}>
                    <div className={styles.buttonText}>{menu?.cta2NavigationButtonText}</div>
                    <MediumRight />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Popover>
      )}
    </li>
  );
});

Dropdown.propTypes = {
  focusNext: PropTypes.func,
  focusPrevious: PropTypes.func,
  isHoldingShift: PropTypes.bool,
  menu: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    headingUrl: PropTypes.string.isRequired,
    dropdownHeading: PropTypes.string,
    menuLinks: PropTypes.any,
    menuFormatType: PropTypes.string,
    cta1NavigationImage: PropTypes.any,
    cta1NavigationIntro: PropTypes.string,
    cta1NavigationLink: PropTypes.string,
    cta1NavigationButtonText: PropTypes.string,
    cta2NavigationIntro: PropTypes.string,
    cta2NavigationLink: PropTypes.string,
    cta2NavigationButtonText: PropTypes.string,
  }),
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
