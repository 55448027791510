import useDetectSSR from './useDetectSSR';
import { useMemo } from 'react';

/**
 * React hook that determines if SSR is being used
 * to render the page or not.
 *
 * @returns whether the page is rendered with SSR
 */
const useDetectIE = () => {
  const isSSR = useDetectSSR();

  const isIE = useMemo(() => {
    if (isSSR) {
      return false;
    }

    return ['MSIE', 'Trident/'].some(string => navigator.userAgent.match(string));
  }, []);

  return isIE;
};

export default useDetectIE;
