import React from 'react';
import { Link } from 'components';
import PropTypes from 'prop-types';
import { safeGet, className, isNonEmptyArray, getQueryParams, renderText } from 'utils';

import styles from './textWidth.module.scss';
import ctaBaseStyles from '../ctaBase.module.scss';

const TextWidth = ({ headline, content, image, buttons }) => {
  const { title: imageTitle, description: imageDescription, url: imageUrl } = safeGet(
    image,
    'title',
    'description',
    ['file', 'url'],
  );

  return (
    <section {...className('component custom-rich-text', styles.textWidth)}>
      <div {...className(ctaBaseStyles.withBackground, styles.wrapper)}>
        <div className={styles.left}>
          {imageUrl && (
            <figure className={styles.imageWrapper}>
              <img
                className={styles.image}
                src={`${imageUrl}${getQueryParams({ w: 300, fit: 'fill' })}`}
                alt={imageDescription || imageTitle}
              />
            </figure>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.copyWrapper}>
            {headline && <h1 className={styles.headline}>{headline}</h1>}
            {renderText({ json: content })}
            {buttons && isNonEmptyArray(buttons) && (
              <div className={styles.buttonWrapper}>
                {buttons.map((button, i) => (
                  <Link className={styles.button} to={button[1]} key={i} newTab>
                    {button[0]}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

TextWidth.propTypes = {
  headline: PropTypes.string,
  content: PropTypes.object,
  image: PropTypes.object,
  buttons: PropTypes.array,
};

export default TextWidth;
