import { createContext } from 'react';

export const THEME_CONTEXT_TYPES = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const KEY_CODES = {
  ESCAPE: 27,
  SHIFT: 16,
};

export const UNICODE = {
  LEFT_ARROW: '\u2190',
  RIGHT_ARROW: '\u2192',
};

export const PHONE_NUMBER_LENGTH = 10;
export const PHONE_NUMBER_FORMAT = 'NNN.NNN.NNNN';
export const GOOGLE_MAPS_SEARCH_URL = `https://www.google.com/maps/search/?api=1`;

export const ThemeContext = createContext('dark');
export const AnimationContext = createContext();
export const BlankTemplateContext = createContext({ isBlankTemplate: false });

export const EMBEDDED_ENTRY_TYPES = {
  BIG_LIST: 'bigList',
  BOOKMARK: 'bookmark',
  BUTTON_LINK: 'buttonLink',
  CAROUSEL: 'carousel',
  CTA: 'cta',
  CUSTOM_RICHTEXT: 'customRichText',
  EXPAND_COLLAPSE: 'expandCollapse',
  FORM: 'Form',
  MEDIA_LAYOUT: 'mediaLayout',
  RECIRCLIST: 'recircList',
  PULL_QUOTE: 'blockQuote',
  STICKY_NAVIGATION: 'stickyNavigation',
  TABLE: 'table',
  VIDEO: 'video',
  INLINE_ICON: 'inlineIcon',
  SPACER: 'spacer',
  COLUMN_CTA: 'columnCta',
  ICON_CTA: 'iconCta',
  TABS: 'tabs',
  SIDEBAR_IMAGE_CTA: 'sidebarImageCTA',
  OPEN_LINK_LIST: 'openLinkList',
  GRID: 'grid',
  SIDEBAR_STICKY_MENU: 'sidebarStickyMenu',
  TIMELINE: 'timeline',
  CUSTOM_ARTICLE_COLUMNS: 'customAndArticleColumnCTA',
  CEROS_EXPERIENCE: 'cerosExperience',
  LINK_TEXT: 'linkText',
  HERO_BANNER: 'heroBanner',
  VIDEO_CAROUSEL: 'videoCarousel',
};

export const RECIRC_LIST_FORMAT = {
  DEFAULT: 'default',
  FLOAT_RIGHT: 'floatRight',
  TEASER: 'teaser',
};

export const HSFORM_EMAIL_SUBSCRIPTION = {
  TITLE: 'Want intelligence that drives advisor results? Subscribe to Commonwealth Insights.',
  FORMID: '34b9e9e4-3141-43eb-977d-fd0a07f4e568',
};

export const COOKIE_POLICY_KEY = 'cookiePolicy';
export const MONTH_IN_MILLIS = 2592000000;

export const CURRENT_LANGUAGE = 'en-US';

export const IMAGE_DIMENSIONS = { w: 475, h: 635, fit: 'fill' };

export const CANVAS_ANIMATION_DURATION = 1;
export const PAGE_ANIMATION_DURATION = 0.2;

export const MENU_TYPE_FORMAT = {
  Number: 'Numbers',
  Hyphen: 'Hyphen',
};

export const CONTENTFUL_ENV_VARIABLES = {
  Development: 'develop',
  Production: 'master',
};

export const CTA_TYPES = {
  LARGE_IMAGE: 'Large Image',
  SMALL_CIRCULAR_IMAGE: 'Small Circular Image',
  SUBSCRIBE: 'Subscribe',
  RELATED_LINKS_SIDEBAR: 'Related Links - Sidebar',
  RELATED_LINKS_FULL_WIDTH: 'Related Links - Full Width',
  FOOTER_ONLY: 'Footer Only CTA',
  TEXT_SIDEBAR: 'Text Sidebar',
  TEXT_WIDTH: 'Text Width',
  SLIDESHARE: 'SlideShare',
};

export const CUSTOM_RICH_TEXT_STYLING = {
  TABLES_NO_VERTICAL_BORDERS: 'Tables - No vertical borders',
  FLOAT_LEFT: 'Float left',
  FLOAT_RIGHT: 'Float right',
};
