const vertex = `
    varying vec2 vUv;
    void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`;

const fragment = `

      uniform float vOpacity;
      uniform float progress;
      uniform float crop;
      uniform float gradient;
      uniform vec3 color;
      uniform sampler2D tDiffuse;

      varying vec2 vUv;

      void main() {

        vec2 p = vUv;
        float fc = 1.;
        if(crop>0.)
        {
          fc = step(abs(1.0*p.x-1.0)+0.5*progress, progress );
        }
        else
        {
          fc = step(1.-progress, abs(1.0*p.x-1.0));
        }
        float grad = 1.;
        if(gradient<1.)
        {
          grad = smoothstep(0.,p.x,gradient) + 0.02;
        }
        gl_FragColor = vec4(color/vec3(grad), vOpacity) * fc;

      }
      `;

export { vertex, fragment };
