import React from 'react';

const WideRightArrow = props => (
  <svg {...props} viewBox="0 0 47 17">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, 1.000000)">
        <line stroke="currentColor" x1="47" y1="7.5" x2="0" y2="7.5"></line>
        <line stroke="currentColor" x1="47" y1="7.5" x2="37" y2="0"></line>
        <line
          stroke="currentColor"
          x1="47"
          y1="15"
          x2="37"
          y2="7.5"
          transform="translate(42.000000, 11.250000) scale(1, -1) translate(-42.000000, -11.250000) "></line>
      </g>
    </g>
  </svg>
);

export default WideRightArrow;
