import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { useArrayRef } from 'hooks';
import Dropdown from './Dropdown';
import { SearchIcon } from 'svgs';
import { className } from 'utils';
import { KEY_CODES } from 'utils/constants';
import styles from './desktop.module.scss';
import baseNavStyles from '../navigation.module.scss';

const DesktopNav = ({ style, navItemsWithDropdowns, utilityLinks, buttonText, buttonLink }) => {
  const getStartedRef = useRef(null);

  const [isHoldingShift, setIsHoldingShift] = useState(false);
  const [dropdownRefs, setDropdownRefs] = useArrayRef();

  const focusIndex = index => {
    const element = dropdownRefs.current[index + 1];
    if (element) {
      element.focus();
    } else {
      getStartedRef.current.focus();
    }
  };

  const handleShiftUp = event => {
    if (event.keyCode === KEY_CODES.SHIFT) {
      setIsHoldingShift(false);
    }
  };

  const handleShiftDown = event => {
    if (event.keyCode === KEY_CODES.SHIFT) {
      setIsHoldingShift(true);
    }
  };

  /**
   * We track whether the user is traversing through the navigation
   * with tab and shift-tab
   */
  useEffect(() => {
    document.body.addEventListener('keyup', handleShiftUp);
    document.body.addEventListener('keydown', handleShiftDown);

    return () => {
      document.body.removeEventListener('keyup', handleShiftUp);
      document.body.removeEventListener('keydown', handleShiftDown);
    };
  }, []);

  return (
    <nav id="js-animate-nav" style={style} className={styles.nav} aria-label="Top Navigation">
      <ul className={styles.navLinks}>
        {navItemsWithDropdowns &&
          navItemsWithDropdowns.map((menuItem, i) => (
            <Dropdown
              key={i}
              isHoldingShift={isHoldingShift}
              focusNext={() => focusIndex(i)}
              focusPrevious={() => focusIndex(i - 1)}
              ref={setDropdownRefs(i)}
              menu={menuItem}
            />
          ))}
      </ul>
      <Link to="/search">
        <SearchIcon {...className(styles.searchIcon)} />
      </Link>
      {buttonLink && (
        <Link
          ref={getStartedRef}
          {...className(baseNavStyles.contact, styles.contact)}
          to={buttonLink}>
          {buttonText}
        </Link>
      )}
      {utilityLinks && (
        <ul className={styles.utilityLinkList}>
          {utilityLinks.map((item, i) => (
            <li key={i} className={styles.utilityLinkItem}>
              <Link
                activeClassName={baseNavStyles.activeLink}
                className={baseNavStyles.utilityLink}
                partiallyActive
                to={item.to}>
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

DesktopNav.propTypes = {
  style: PropTypes.any,
  navItemsWithDropdowns: PropTypes.any,
  utilityLinks: PropTypes.any,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};

export default DesktopNav;
