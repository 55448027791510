import React from 'react';
import BleedRight from '../../MediaLayout/BleedRight';
import PropTypes from 'prop-types';
import { className, renderText } from 'utils';
import styles from './overflowRight.module.scss';
import ctaBaseStyles from '../../CTA/ctaBase.module.scss';
import { Link } from 'gatsby';

const OverflowRight = ({ link, buttonText, media, caption }) => (
  <div {...className(styles.leftCap, ctaBaseStyles.withBackground)}>
    <div className={styles.leftContent}>
      {caption && <div className={styles.leftCapContent}>{renderText({ json: caption })}</div>}
      <div className={styles.buttonWrapper}>
        <Link data-automation-id="overflow-right-button" className={styles.button} to={link} newTab>
          {buttonText}
        </Link>
      </div>
    </div>
    <BleedRight
      media={[media]}
      className={className(styles.imageRight).className}
      imgHeight={900}
      imgWidth={600}
    />
  </div>
);

OverflowRight.propTypes = {
  media: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  caption: PropTypes.shape({
    json: PropTypes.object.isRequired,
  }),
  link: PropTypes.string,
  buttonText: PropTypes.string,
};

export default OverflowRight;
