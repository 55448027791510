import React from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, THEME_CONTEXT_TYPES } from 'utils/constants';
import 'style/index.scss';

const wrapRootElement = ({ element }) => (
  <ThemeContext.Provider value={THEME_CONTEXT_TYPES.DARK}>{element}</ThemeContext.Provider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export default wrapRootElement;
