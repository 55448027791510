import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from './LoadingScreen';
import LoadingComponent from './LoadingComponent';

const Loading = forwardRef(({ type }, ref) => {
  const mapping = {
    ['Screen']: LoadingScreen,
    ['Component']: LoadingComponent,
  };

  const _component = mapping[type];

  return _component ? <_component ref={ref} type={type} /> : null;
});

Loading.propTypes = {
  type: PropTypes.string.isRequired,
};

Loading.displayName = 'Loading';

export default Loading;
