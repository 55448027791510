import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import { Link } from 'components';
import styles from './openLinkList.module.scss';

const OpenLinkList = ({ options = [], title, submitButtonText }) => {
  const [selectedLink, setSelectLink] = useState('');

  useEffect(() => {
    if (options.length > 0) {
      setSelectLink(options[0][1]);
    }
  }, [options]);

  const handleChange = e => {
    setSelectLink(e.target.value);
  };

  return (
    <div {...className('component', styles.openLinkList)}>
      {!!title && <label>{title}</label>}
      <select value={selectedLink} onChange={handleChange}>
        {options.map(option => (
          <option key={option[1]} value={option[1]}>
            {option[0]}
          </option>
        ))}
      </select>
      <Link to={selectedLink} className={styles.link}>
        {submitButtonText}
      </Link>
    </div>
  );
};

OpenLinkList.propTypes = {
  options: PropTypes.array,
  title: PropTypes.string,
  submitButtonText: PropTypes.string,
  className: PropTypes.string,
};

export default OpenLinkList;
