import React, { useState } from 'react';
import { navigate, useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { RichTextCarousel as Carousel, MediaLayout } from 'components';
import { handleLogin, isLoggedIn } from '../../services/auth';
import { className } from 'utils';
import { carouselItemsPropTypes, imagePropTypes } from 'utils/proptypes';
import formStyles from '../ContactForm/contactForm.module.scss';
import styles from './login.module.scss';

const Login = ({ passwordMatch, carousel, mediaLayout }) => {
  const location = useLocation();
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [unmasked, setUnmasked] = useState(false);

  const handleUpdate = event => {
    setPassword(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    handleLogin({ password, passwordMatch });
  };

  const handleMouseDown = () => {
    setUnmasked(true);
  };

  const handleMouseUp = () => {
    setUnmasked(false);
  };

  return (
    <>
      <MediaLayout
        className={styles.featuredImage}
        imgHeight={900}
        imgWidth={600}
        media={mediaLayout.media}
        caption={mediaLayout.caption}
        type={'Bleed Right'}
      />
      <p className={styles.intro}>Please enter the password provided to you by Commonwealth.</p>
      <form
        method="post"
        onSubmit={event => {
          handleSubmit(event);
          isLoggedIn(passwordMatch) ? navigate(location.pathname) : setError('password');
        }}
        {...className(formStyles.formSection, styles.formSection)}>
        <div>
          <label aria-label="Password" htmlFor="password">
            <input
              {...className(
                unmasked ? styles.passwordUnlock : styles.passwordLock,
                styles.password,
              )}
              autoComplete="true"
              id="password"
              name="password"
              onChange={e => handleUpdate(e)}
              placeholder="Password"
              size="30"
              tabIndex="-1"
              type={unmasked ? 'text' : 'password'}
            />
            <i
              className={styles.hiddenEye}
              role="button"
              tabIndex="0"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}></i>
          </label>
          {error === 'password' && <div className={styles.errorMsg}>Password was incorrect.</div>}
        </div>
        <div>
          <input type="submit" value="Log In" />
        </div>
      </form>

      <Carousel
        title={carousel.title}
        description={carousel.description}
        name={carousel.name}
        carouselItems={carousel.carouselItems}
      />
    </>
  );
};

Login.propTypes = {
  passwordMatch: PropTypes.string,
  mediaLayout: imagePropTypes,
  carousel: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.shape({
      description: PropTypes.string,
    }),
    carouselItems: carouselItemsPropTypes,
  }),
};

export default Login;
