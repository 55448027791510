import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { className, renderText } from 'utils';
import { menuPropTypes } from 'utils/proptypes';
import styles from './dropdownMenu.module.scss';

const DropdownMenu = ({ className: customClassName, menu }) => (
  <nav {...className(styles.menu, customClassName)}>
    {menu.heading && (
      <div className={styles.dropdownMain}>
        {menu.headingUrl ? <Link to={menu.headingUrl}>{menu.heading}</Link> : menu.heading}
        <span className={styles.arrow} />
      </div>
    )}
    <div className={styles.dropdownContainer}>{renderText({ json: menu.list })}</div>
  </nav>
);

DropdownMenu.propTypes = {
  className: PropTypes.string,
  menu: menuPropTypes.isRequired,
};

export default DropdownMenu;
