import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { carouselItemsPropTypes } from 'utils/proptypes';
import styles from './videoCarousel.module.scss';
import RichTextCarousel from '../RichTextCarousel/index.js';
import { className } from 'utils';

const VideoCarousel = ({ name, carouselItems }) =>
  isEmpty(carouselItems) ? null : (
    <div {...className('component', styles.videoCarousel)} id={name}>
      <RichTextCarousel name={name} carouselItems={carouselItems} isVideoCarousel={true} />
    </div>
  );

VideoCarousel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  carouselItems: carouselItemsPropTypes.isRequired,
  name: PropTypes.string.isRequired,
};

export default VideoCarousel;
