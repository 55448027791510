import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components';

import { safeGet, renderText, getQueryParams, className } from 'utils';
import { imagePropTypes } from 'utils/proptypes';

import styles from './topperContentRight.module.scss';

const TopperContentRight = ({ intro, image, format, link }) => {
  const { title, description, url } = safeGet(image, 'title', 'description', ['file', 'url']);

  const hasMedia =
    JSON.stringify(intro).includes('Text Width') || JSON.stringify(intro).includes('Video');

  const TopperImage = () =>
    url ? (
      <img
        className={styles.image}
        alt={description || title}
        src={`${url}${getQueryParams({ w: 200, h: 200, fit: 'fill' })}`}
      />
    ) : null;

  return (
    <div
      {...className(
        styles.smallBlock,
        (format === 'Row' || !format) && styles.row,
        format === 'Box' && styles.box,
        format === 'Box' && !hasMedia && styles.outline,
        'js-animate-profiles',
      )}>
      {format === 'Row' && link ? (
        <Link to={link} className={styles.linkWrapper}>
          <div className={styles.imageWrap}>
            <div className={styles.clippingBox}>
              <TopperImage />
            </div>
          </div>
        </Link>
      ) : (
        <TopperImage />
      )}
      <div className={styles.text}>
        {intro && renderText(intro)}
        {link && !hasMedia && format === 'Box' && (
          <p>
            <Link to={link} className={styles.readMore}>
              read more <span className={styles.readMoreArrow}>&rarr;</span>
            </Link>
          </p>
        )}
      </div>
    </div>
  );
};

TopperContentRight.propTypes = {
  intro: PropTypes.object,
  image: imagePropTypes,
  link: PropTypes.string,
  format: PropTypes.string,
};

TopperContentRight.defaultProps = {
  format: 'Row',
};

export default TopperContentRight;
