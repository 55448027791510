import React from 'react';
import PropTypes from 'prop-types';
import { className, isNonEmptyArray } from 'utils';
import styles from './imageCTA.module.scss';
import ImageCTAGridItem from './ImageCTAGridItem';

const ImageCTA = props => {
  const columnData = [1, 2, 3, 4].map(index => ({
    image: props[`image${index}`],
    label: props[`label${index}`],
    headline: props[`headline${index}`],
    intro: props[`intro${index}`],
    ctaUrl: props[`ctaUrl${index}`],
    labelUrl: props[`labelUrl${index}`],
    videoIcon: props.videoIcon,
  }));

  const validColumns = columnData.filter(el => el.headline);
  const isThreeColumnWrapper = validColumns.length === 3;

  return (
    <ul {...className(styles.wrapper, isThreeColumnWrapper && styles.threeColumnWrapper)}>
      {isNonEmptyArray(validColumns) &&
        columnData.map((data, i) => <ImageCTAGridItem key={i} {...data} />)}
    </ul>
  );
};

ImageCTA.propTypes = {
  videoIcon: PropTypes.string,
};

export default ImageCTA;
